<template>
<!-- <div class="pa-1" @mousedown="ver_se_logado()"> -->
  <div v-if="can_show">


    <!-- obj_dados_empresa: {{obj_dados_empresa}} <br/> -->
    <!-- item_menu_copia:{{item_menu_copia}}<br/><br/> -->
    <!-- item_menu:{{item_menu}} <br/><br/> //quando clico com o botao direito -->

    <!-- {{header_conta}} -->
    <!-- janela_modal_abre: {{janela_modal_abre}} <br/> -->
    <!-- zapzap_mostrar: {{zapzap_mostrar}} <br/> -->
    <!-- zapzap_conectado: {{zapzap_conectado}}<br/> -->
    <!-- filtros:{{filtros}} <br/> -->
    <!-- filtros.categoria:{{filtros.categoria}} <br/> -->
    <!-- categoria_forma:{{categoria_forma}}<br/> -->
    <!-- items_estat_formas: {{items_estat_formas}} <br/> -->
    <!-- items_somatorias: {{items_somatorias}} <br/> -->
    <!-- atrasadas_model : {{atrasadas_model}} <br/><br/> -->
    <!-- previsao_model : {{previsao_model}} <br/><br/> -->
    <!-- items_estat_atrasadas : {{items_estat_atrasadas}} <br/><br/> -->
    <!-- items_estat_previsao : {{items_estat_previsao}} <br/><br/> -->
    

    <!-- obj_user:{{obj_user}} <br/><br/> -->
    <!-- chart_quero:{{chart_quero}} <br/> <br/> -->
    <!-- chart_comparar:{{chart_comparar}} <br/> <br/> -->
    <!-- items_chart:{{items_chart}} <br/> <br/> -->
    <!-- chart_values:{{chart_values}} <br/> <br/> -->
    <!-- chart_labels:{{chart_labels}} <br/> <br/> -->
    <!-- {{items_estat_counts}} -->
    <!-- {{ last_parametros }}   -->
    <!-- edita_parcelas:{{edita_parcelas}} <br/> -->
    <!-- {{selectableItems}} -->
    <!-- {{search_conta}} -->
    <!-- {{dados_parcial}}<br/><br/> -->
    <!-- {{items_tarefas}} -->
    <!-- {{items_estat_categoria}} -->
    <!-- {{sqlWhere_2}} -->
    <!-- {{items_data}} -->
    <!-- {{value_expansion}} -->
    <!-- {{expansion_selecionao}} -->
    <!-- {{menu_active}} -->
    <!-- {{comp_focus}} -->
    <!-- item_clicou:{{item_clicou}} -->
    <!-- {{filtros}} -->
    <!-- config_emp:{{config_emp}} <br/><br/> -->
    <!-- {{filtros}} -->

    <!-- {{acao_atual}} -->
    <!-- {{send_conta}} -->
    <!-- {{zap_status}} -->
    <!-- {{config_whats}} -->
    <!-- {{plataforma_cobranca}} <br/> -->
    <!-- config_pix:{{config_pix}} <br/><br/> -->

    <!-- {{ items_tabela_categoria }} -->
    <!-- {{ items_tabela_operacao }} -->
    <!-- selected: {{selected}} <br/><br/> -->
  
    <!-- di:{{ di }} &nbsp df: {{ df }} <br/> -->
    <!-- {{ filtros.tipo_data }} <br/> -->
    <!-- tipo_dia:{{tipo_dia}} <br/>   -->
    <!-- {{selected}} -->
    <!-- {{selected_copia}} -->
    <!-- items_conta:{{ items_conta }} -->
    <!-- {{ selected }} -->
    <!-- {{ today }}  -->
    <!-- showJuros:{{ showJuros }} -->
    <!-- souDSi:{{ souDSi }} -->
    <!-- {{ somas   }} -->
    
    <!-- {{  items_conta  }} -->
  
    <!-- {{ selecionou_varios }} -->
    <!-- {{ selected }} -->

    <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>



      <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_ok"
          width="500"
          persistent
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{  (dialog_ok_tipo == 'conectar_zap') ? 'Concluído' : 'Concluído'  }}
            </v-card-title>

            <v-card-text>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_ok_clicou(dialog_ok_tipo), dialog_ok=false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>


    <div class="text-center">
      <v-dialog v-model="chart_dialog" width="600" >

          <v-card
            class="mx-auto text-center"
            color="grey lighten-4"
            
            max-width="600"
          >
           <v-card-title>
            {{chart_title}}
           </v-card-title>

            <v-card-text>
              <v-sheet :color="chart_color" elevation="12">
                <v-sparkline
                  :labels="chart_labels"    
                  :value="chart_values"
                  color="white"
                  height="100"
                  padding="24"
                  stroke-linecap="round"
                  smooth
                >
                
                
                  <template v-slot:label="item">
                    {{ item.value }}                   
                  </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>

            <v-card-text>
              <div class="text-h5 font-weight-thin">
                Últimos 6 meses
              </div>
            </v-card-text>


            <v-divider></v-divider>

            <v-card-actions class="justify-end">

              <v-row>
                <v-col class="" cols="6">        
                      <v-checkbox
                            v-model="chart_comparar"
                            label="Comparar + "
                            dense
                            class="pa-0"
                            hide-details
                        >
                    </v-checkbox>
                </v-col>
              </v-row>


              <v-btn
                text 
                color="red"
                @click="chart_dialog=false"
              >
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>

      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialog_qrcode" width="700" persistent>

        <v-card>
          <v-card-title class="text-h6 grey lighten-5 justify-center">
            QRCode da Instancia
          </v-card-title>

          <v-card-text>
            <v-img
              alt="user"
              :src="img_qrcode_zap"
              height="30%"
            >
          </v-img>


          <iframe :src="url_externo" frameborder="0" height="400px" width="100%" ></iframe>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              x-large
              @click="dialog_qrcode = false, status_zap()"
            >
              FECHAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>


  <v-snackbar
      v-model="snackbar"
      :timeout="snack_timeout"
      top
      :color="snack_color"    
      style="margin-top: 30px"
  >
  <center v-html="snack_text"></center>
  <!-- <span v-html="snack_text"></span> -->
  

  </v-snackbar>


  <div class="text-center">
    <v-dialog v-model="dialog_opcoes" width="500">

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Transferência entre contas
        </v-card-title>

        <v-card-text class="mt-4">
          Saldo na Conta  <b>{{ plataforma_cobranca }}</b>: <b class="blue--text"> R$ {{ getFormatCurrency(cobranca_sum) }}</b>

          <v-form
            ref="form"
            v-model="valido"
            >

            <v-row class="mt-3">

              <v-col cols="6" class="mt-3">
                <v-select
                  v-model="send_conta"
                  label="Transferir para"
                  :items="items_tabela_contas_pix"
                  item-value='DESCRICAO'
                  item-text='DESCRICAO'
                  return-object
                  dense
                  :clearable = "false"
                  hide-details
                  :rules="notnullRules"
                  >
                  
                  <template slot='selection' slot-scope='{ item }'>
                  {{ item.DESCRICAO }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="5" class="ml-4">
                <v-text-field
                  v-model="send_valor"
                  flat
                  label="Valor"
                  clearable
                  hide-details
                  class="mt-4"
                  :rules="notnullRules"
                  autofocus
                  @keyup="send_valor = moeda($event)"
                  prefix="R$"
                  dense
                ></v-text-field>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_opcoes = false"
          >
            cancelar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_opcoes = false, transferir_saldo()"
            :disabled="!valido"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 


  <div class="text-center">
    <v-dialog v-model="dialog_img" width="500">

      <v-card>
        <v-card-title class="text-h6 white lighten-2 justify-center">
          QRCode da Instancia
        </v-card-title>

        <v-card-text>
          <v-img
            alt="user"
            :src="img_qrcode_zap"
            height="30%"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_img = false, status_zap()"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


  <!-- MENU -->
  <v-dialog
      v-model="dialog_menu"
      max-width="300px"
      scrollable
    >



    <v-list dense>

    <!-- <v-subheader class="grey lighten-3">
      <h4 class="ml-1"> Menu </h4>
    </v-subheader> -->

  <v-divider class="blue mx-2"></v-divider>


  <span v-for="item in menu_parcelas" :key="item.title">
    <!-- <v-divider class="orange mx-2"></v-divider> -->

      <v-list-item
            link
            @click="clicou_menu_parcelas(item.click)"
            v-if="(item.type=='menu')"
          >
          
          <v-list-item-icon>
              <v-icon  :color="item.icon_color"> {{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>

      </v-list-item>
      
      <!-- <v-divider
          class="mx-2"
      ></v-divider> -->
  </span>
  


  <span v-for="(item, index) in menu_parcelas" :key="item.title+index" v-if="(item.type=='group')&&(vif_here(item.title))">

    <v-list-group
        v-model="(selecionou_varios)  ? false : item.active"    
        no-action
        @click.stop="" 
    >
    

    <!-- v-model="(selecionou_varios) ? false : item.active" -->
    <!-- v-model="(item.title=='ÍTENS SELECIONADOS') ? fuba : item.active" -->
    <!-- {{['grupo'+'_'+index]}} -->

        <template v-slot:activator>

          <v-list-item-icon class="mr-1">
            <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content >
              <!-- <v-list-item-title v-text="item.title"></v-list-item-title> -->
              <v-list-item-title>
                <span v-if="item.title=='ESTE'">

                  <div class="white lighten-5 mx-0 mb-1">
                    <h4 v-if="item_menu.X_NOME_CLIENTE"> {{ item_menu.X_NOME_CLIENTE }} </h4>
                    <h4 v-if="item_menu.X_NOME_ALUNO"> {{ item_menu.X_NOME_ALUNO }} </h4>
                    <h4 v-if="(item_menu.CATEGORIA)&&(item_menu.TIPO == 'S')"> {{ item_menu.CATEGORIA }} </h4>
                    <h5> ({{ item_menu.CODIGO }}) - R$ {{ item_menu.double_TOTAL }}</h5>
                  </div>
                </span>
                <span v-else>
                  <!-- ÍTENS SELECIONADOS -->
                  {{ item.title }} &nbsp ({{ selected.length }})
                </span>
              </v-list-item-title>
          </v-list-item-content>
        </template>


        <v-divider class="blue mx-2"></v-divider>

        <span v-for="sub in item.items" :key="sub.title"   v-if="vif_here(sub.click)">

          <v-list-item
          link
          @click="clicou_menu_parcelas(sub.click)"
          class="px-12"
          >

            <v-icon :color="sub.icon_color" small>
              {{ sub.icon }}
            </v-icon>

            <v-list-item-content class="ml-3">
                <v-list-item-title v-text="sub.title"></v-list-item-title>
            </v-list-item-content>

          </v-list-item>
          <v-divider class="mx-5"></v-divider>

      
        </span>

    </v-list-group>


    <v-divider class="blue mx-2 mt-2"></v-divider>
  </span>



    </v-list>

  </v-dialog>  


    <!-- ALTERAR PARCELA -->
    <v-dialog
        v-model="dialog_alterar_parcela"
        persistent
        max-width="990px"
        scrollable
        
    >

        <v-card scrollable class="">
        <!-- class="overflow-y-auto px-0 py-0" -->
            <v-card-title 
              :class="(edita_parcelas.TIPO=='E') ? 'green lighten-4' : 'red lighten-4'"
            >
                <span class="text-h5" v-if="edita_parcelas.CODIGO">Alterar Lançamento</span> 
                <span class="text-h5" v-else>Novo Lançamento</span> 
                &nbsp <span class="text-caption"> {{ edita_parcelas.CODIGO  }} </span>
            </v-card-title>

            <v-form
            v-model="valido"
            >
            
            
                <v-card-text class="">
                    <v-container class="">
                        <v-row  class="">

                            <v-col
                                class=""
                                cols="12"
                                :md=" (edita_parcelas.PAGO=='S') ? 2: 2"
                            >   
                            <!-- :md=" ((showJuros)&&(check_juros_aqui))  ? 3 : (edita_parcelas.PAGO=='S') ? 2: 3" -->

                                <v-select
                                  v-model="edita_parcelas.CODIGO_CONTA"
                                  label="Conta Referente"
                                  :items="items_tabela_contas"
                                  
                                  item-value='CODIGO'
                                  item-text='DESCRICAO'

                                  :return-object="true"

                                  @change="change_conta"

                                  :rules="notnullRules"
                                  
                                  dense
                                  >
                                  <!-- @change="oi($event, 'vai texto')" -->
                                  <!-- @change="oi($event)" -->
                                  <!-- @input="oi2" -->
                                  <!-- @change="edita_parcelas.CONTA_REFERENTE='XXXX'" -->
                                </v-select>
                            </v-col>


                            <v-col
                            :cols="(isMobile)? 12 : 3"
                            class=""

                            >
                              <v-row>
                                <v-col :cols="(isMobile)? 4 : 4">

                                  <v-select
                                      v-model="multiplicar"
                                      type="number"
                                      label="Vezes"
                                      :items="lista_parcelas"
                                      dense
                                      hide-details="true"
                                  >
                                      <!-- <template slot='item' slot-scope='{ item }'>
                                        <span class="blue--text" style="font-size:12px !important"> {{ item }} x</span>
                                      </template> -->

                                      <template slot='selection' slot-scope='{ item }'>
                                        <span class="blue--text"> {{ item }}</span>
                                      </template>
                                  </v-select>
                                </v-col>
                                
                                <v-col :cols="(isMobile)? 8 : 8">
                                  <v-text-field
                                    v-model="edita_parcelas.double_VALOR"
                                    label="Valor"
                                    prefix="R$"
                                    dense
                                    autofocus
                                    :rules="notnullRules"    
                                    @keyup="edita_parcelas.double_VALOR = moeda($event), calcula()"
                                    @keyup.enter="focus_by_id('id_forma')"
                                    hide-details="true"
                                >
                                </v-text-field>

                                  <div v-if="(showJuros)" class="mt-0">
                                    <v-checkbox
                                            v-model="check_juros_aqui"
                                            label="JUROS"
                                            dense
                                            style="margin-left: -4px"
                                            class="pa-0"
                                            hide-details="true"
                                        >
                                        <template v-slot:label>
                                          <span class="blue--text ml-n2" style="font-size:12px !important">Juros / Taxas</span>
                                        </template>
                                    </v-checkbox>

                                  </div>

                                </v-col>

                              </v-row>

                            </v-col>

                            <v-col v-if="(showJuros)&&(check_juros_aqui)" class="" :cols="(isMobile)? 12 : 5">
                              <v-row>

                                <v-col cols="4" >
                                    <v-text-field
                                        v-model="edita_parcelas.double_JUROS"
                                        label="+ Juros"
                                        prefix="R$"
                                        dense
                                        @keyup="edita_parcelas.double_JUROS = moeda($event), calcula()"
                                    >
                                    
                                    </v-text-field>
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field
                                        v-model="edita_parcelas.double_TAXA"
                                        label="- Taxa"
                                        prefix="R$"
                                        dense   
                                        @keyup="edita_parcelas.double_TAXA = moeda($event), calcula()"
                                    >
                                    
                                    </v-text-field>
                                </v-col>

                                <v-col cols="4">
                                    <v-text-field
                                        v-model="edita_parcelas.double_TOTAL"
                                        label="Total"
                                        prefix="R$"
                                        dense
                                        :rules="notnullRules"    
                                        @keyup="edita_parcelas.double_TOTAL = moeda($event)"
                                        hide-details="true"
                                    >
                                  
                                    
                                    </v-text-field>


                                    <div v-if="(showJuros)" class="mt-1">
                                      <v-btn
                                          color="primary"
                                          x-small text
                                          @click="acha_juros()"
                                        >
                                         Achar Juros
                                        </v-btn>

                                  </div>

                                </v-col>

                              </v-row>
                            </v-col>


                            <!-- </v-row> -->

                            <!-- <v-row> -->

                            <v-col
                            :cols="(isMobile)? 12 : 6"
                            class=""
                            :md="(check_juros_aqui) ? 2: 3"
                            >
                            
                            <!-- :md="((showJuros)&&(check_juros_aqui)) ? 3 : (edita_parcelas.PAGO=='S') ? 2: 3" -->

                            <v-select
                                    v-model="edita_parcelas.FORMA_PAGAMENTO"
                                    label="Forma"
                                    :items="items_tabela_formas"
                                    item-value='DESCRICAO'
                                    item-text='DESCRICAO'
                                    return-object
                                    dense
                                    :clearable = "false"
                                    @change="seleciona_forma_pagamento()"
                                    id="id_forma"
                                    >
                                    <!-- @keyup.enter="hideMenu" -->
                                    <!-- @keypress.enter="focus_by_id('id_categoria')" -->
                                    
                                    <template slot='selection' slot-scope='{ item }'>
                                     <span class="fs-12"> {{ item.DESCRICAO }}</span>
                                    </template>
                                </v-select>
                            </v-col>


                            <v-col
                            cols="6"
                            md="2"
                            >
                              <v-text-field
                                  v-model="edita_parcelas.DATA_VENCIMENTO"
                                  type="date"
                                  label="Vencimento"
                                  dense
                                  @keyup.enter="focus_by_id('id_categoria')"
                              >
                              </v-text-field>


                            </v-col>

                            <v-col class=""
                            cols="6"
                            md="2"
                            
                            >
                              <v-text-field
                                  v-if="(edita_parcelas.PAGO=='S')"
                                  v-model="edita_parcelas.DATA_EFETUADO"
                                  type="date"
                                  label="Efetuado"
                                  dense
                              >
                              </v-text-field>

                              <div :class="(edita_parcelas.PAGO=='S')? 'mt-n4' : 'mt-2'">
                                <v-checkbox
                                    v-model="checkbox_efetuado"
                                    label="EFETUADO"
                                    dense
                                    style="margin-left: -4px"
                                    @click="muda_pago(checkbox_efetuado)"
                                    class="pa-0"
                                    hide-details="true"
                                >
                                    <template v-slot:label>
                                      <span :class="[(edita_parcelas.PAGO=='S') ? 'fs-12': '', 'blue--text']">EFETUADO</span>
                                    </template>
                                </v-checkbox>
                              </div>

                            </v-col>





                        </v-row>

                        <v-row>

                          <v-col
                               class=""
                               cols="12"
                               md="4"
                           >   
                               <v-combobox
                                 v-model="edita_parcelas.CATEGORIA"
                                 label="Categoria - Operação"
                                 :items="items_tabela_categoria"
                                 item-value='CATEGORIA'
                                 item-text='CATEGORIA'
                                 :return-object="false"
                                 :search-input.sync="search_combobox"
                                 dense
                                 clearable
                                 id="id_categoria"
                                 @input="apertou_enter('CATEGORIA'), allUpper($event,'edita_parcelas', 'CATEGORIA')" 
                                 @keyup.esc="edita_parcelas.CATEGORIA=''"
                                 @keyup.enter="focus_by_id('id_referente')"
                               >

                               <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Para adicionar na lista pressione <kbd>enter</kbd>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>


                                <!-- <template slot='selection' slot-scope='{ item }'>
                                 {{ item}} 
                                </template> -->

                                <template slot='item' slot-scope='{ item }'>
                                  <span class="text-overline ma-0 pa-0">
                                    <span v-if="(item.CATEGORIA)">
                                      {{ item.CATEGORIA }} 
                                      <!-- {{ item.TIPO }} -->
                                    </span> 
                                    <span v-else>
                                      {{ item }} 
                                    </span> 
                                    
                                  </span>

                                  <v-spacer></v-spacer>
                                  <v-list-item-action @click.stop class="ma-0 pa-0">
                                    <v-btn
                                      icon
                                      class="ma-0 pa-0"
                                      @click.stop.prevent="remove_lista('CATEGORIA', item)"
                                      v-if="(item.TIPO=='C')"
                                    >
                                    <!-- item.TIPO = c=de contas l=tabela_categorias  -->
                                      <v-icon x-small> mdi-close-thick</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </template>

                               </v-combobox>
                           </v-col>

                          <v-col
                               class=""
                               cols="12"
                               md="6"
                               v-if="(0>1)"
                           >   
                               <v-combobox
                                 v-model="edita_parcelas.OPERACAO"
                                 label="Operação"
                                 :items="items_tabela_operacao"
                                 item-value='OPERACAO'
                                 item-text='OPERACAO'
                                 :return-object="false"
                                 dense
                                 clearable
                                 @input="apertou_enter('OPERACAO'), allUpper($event,'edita_parcelas', 'OPERACAO')"  
                               >

                               <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Para adicionar na lista pressione <kbd>enter</kbd>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>

                               </v-combobox>
                           </v-col>


                            <v-col
                            cols="12"
                            md="8"
                            class=""
                            >
                            <v-text-field
                                v-model="edita_parcelas.SERVICO_REFERENTE"
                                type=""
                                label="Referente"
                                dense    
                                id="id_referente"

                                lazy-validation   
                                
                                @keypress.enter="salvar_calculando()"
                                :rules="notnullRules"
                                @input="allUpper($event,'edita_parcelas', 'SERVICO_REFERENTE')"                                
                            >

                            
                            </v-text-field>

                            </v-col>


                            <!-- <v-col
                            cols="12"
                            md="4"
                            class=""
                            v-if="(edita_parcelas.CODIGO_ALUNO>0)"
                            >


                              <v-chip  
                                color=""
                                class="font-weight-medium"
                                small
                              >
                               {{ (edita_parcelas.X_NOME_ALUNO) }} 
                                
                              </v-chip>
                              
                            </v-col> -->

                            <v-col
                            cols="12"
                            md="12"
                            class=""
                            v-if="(edita_parcelas.CODIGO_ALUNO>0)"
                            >
                              <v-text-field
                                  v-model="edita_parcelas.OBSERVACAO"
                                  type=""
                                  label="Anotação"
                                  dense
                                  @keydown="firstUpper($event, edita_parcelas.OBSERVACAO)"
                              >
                              </v-text-field>
                              
                            </v-col>
                            



                            <span v-if="0>1">
                              <v-col
                              cols="2"
                              md="2"
                              class=""
                              v-if="(souDSi)"
                              >
                              <v-text-field
                                  v-model="edita_parcelas.ENVIOU_EMAIL"
                                  type=""
                                  label="ENVIOU_EMAIL"
                                  dense
                                  id="id_referente"
                              >
                              </v-text-field>

                              </v-col>

                              <v-col
                              cols="2"
                              md="2"
                              class=""
                              v-if="(souDSi)"
                              >
                              <v-text-field
                                  v-model="edita_parcelas.ENVIOU_WHATS"
                                  type=""
                                  label="ENVIOU_WHATS"
                                  dense
                                  id="id_referente"
                              >
                              </v-text-field>

                              </v-col>

                              <v-col
                              cols="12"
                              md="2"
                              class=""
                              v-if="(0>1)"
                              >
                              <v-text-field
                                  v-model="edita_parcelas.CODIGO_CLIENTE"
                                  type=""
                                  label="Cod.Cliente"
                                  dense
                              >
                              </v-text-field>

                              </v-col>
                            </span>


                        </v-row>

                        <v-row dense v-if="2>1">
                          <v-col
                            :cols="(isMobile)? 12 : 6"
                            class=""
                            md="1"
                            v-if="(souDSi)"
                            >
                            
                            <!-- :md="((showJuros)&&(check_juros_aqui)) ? 3 : (edita_parcelas.PAGO=='S') ? 2: 3" -->

                            <v-select
                                    v-model="edita_parcelas.TIPO"
                                    label="Tipo"
                                    :items="['E','S','2']"
                                    dense
                                    >
                                </v-select>
                            </v-col>
                          
                          <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.PIX_ID"
                                type="text"
                                label="PIX ID"
                                dense
                            >
                            </v-text-field>
                            </v-col>
                          
                            <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.PIX_URL"
                                type="text"
                                label="PIX URL"
                                dense
                            >
                            </v-text-field>
                            </v-col>
                          
                           <v-col
                            cols="6"
                            md="5"
                            class=""
                            v-if="(edita_parcelas.TIPO=='S')"
                            >

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn x-small fab color="primary"  @click="abre_janela_modal(true, false, 'ALUNOS', 'CADASTRO')" v-bind="attrs" v-on="on">
                                  <v-icon>mdi-account-search </v-icon>
                                </v-btn>
                            </template>
                              <span> Selecionar Cadastro </span>
                            </v-tooltip>


   

                            

                            <span v-if="(edita_parcelas.CODIGO_ALUNO>0)">
                              <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- <v-btn x-small fab color="error" class="ml-2" @click="remover_aluno()" v-bind="attrs" v-on="on"> -->
                                  <v-icon color="error" class="ml-2 hand" v-bind="attrs" v-on="on" @click="remover_aluno()">mdi-close </v-icon>
                                <!-- </v-btn> -->
                            </template>
                              <span> Remover Cadastro </span>
                              </v-tooltip>
                            </span>


                         
                            <v-chip v-if="(edita_parcelas.CODIGO_ALUNO>0)" 
                                color="blue lighten-3" 
                                small class="ma-1 ml-2 font-weight-medium"
                              >
                                {{ edita_parcelas.X_NOME_ALUNO }}
                              </v-chip>
                            

                            </v-col>
                          
                             <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.ENVIOU_EMAIL"
                                type="text"
                                label="ENVIOU_EMAIL"
                                dense
                            >
                            </v-text-field>
                            </v-col> 
                            
                             <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.ENVIOU_WHATS"
                                type="text"
                                label="ENVIOU_WHATSs"
                                dense
                            >
                            </v-text-field>
                            </v-col> 
                            
                            <!-- <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.CODIGO_ALUNO"
                                type="text"
                                label="ENVIOU_ALUNO"
                                dense
                            >
                            </v-text-field>
                            </v-col> -->
                            
                            <!-- <v-col
                            cols="6"
                            md="2"
                            v-if="(souDSi)"
                            >
                            <v-text-field
                                v-model="edita_parcelas.CODIGO_SERVICO"
                                type="text"
                                label="ENVIOU_SERVICO"
                                dense
                            >
                            </v-text-field>
                            </v-col> -->
                        </v-row>

                    </v-container>
                </v-card-text>

            </v-form>

            <v-card-actions>

                <v-spacer></v-spacer>

                <v-btn
                    color="red"
                    text
                    @click="dialog_alterar_parcela=false, dados_parcial=null"
                >
                    Cancelar
                </v-btn>
                
                
                &nbsp

                <v-btn
                    :disabled="!valido"
                    color="primary"
                    type="submit"
                    @click="salvar_calculando()"
                >
                    Salvar.
                    <!-- SALVAR PARCELA -->
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>







<!-- PAINEL 1 FILTROS -->
<v-card
 elevation="3"
 class=" lighten-2 pt-2 "
 @mouseover="comp_focus=''"
 v-if="(ocultar_quando('basico'))"
>
<!-- height="70px" -->
  <v-container>
  <v-row  class="">

    
    <!-- coluna 1 -->
    <v-col
        :class="[(isMobile)? 'pb-0 px-3 pl-2': 'pb-0 px-0 pl-2']"
        cols="12"
        md="2"
        lg="2"
    >   
    
      <v-row>

        <v-col cols="1" class="px-0 mr-3 pl-3" v-if="2>1">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">

              <v-icon  
                v-bind="attrs"
                v-on="on"
                color="blue"
                size="38"
                class=""
              >
                <!-- mdi-dots-vertical -->
                mdi-chevron-down 
              </v-icon> 


            </template>

            <v-card class="mx-auto">
                <v-list dense>

                    <v-divider class="mx-0"></v-divider>

                    <v-list-item
                         v-for="item in menu_tipo_data"
                         :key="item.title"
                         link
                         @click="clicou_menu_tipo_data(item.click)"
                         v-if="item.type=='menu'"
                        >

                        <v-list-item-icon>
                            <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>

                    </v-list-item>



                    <v-list-group
                        v-for="item in menu_tipo_data"
                        :key="item.title"
                        v-model="item.active"
                        
                        no-action
                        @click.stop="" 
                        v-if="(item.type=='group')"
                    >

                        <template v-slot:activator>

                          <v-list-item-icon>
                            <v-icon :color="item.icon_color" small> {{ item.icon }}</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                              <v-list-item-title v-text="item.title"></v-list-item-title>
                          </v-list-item-content>
                        </template>


                        <v-list-item
                         v-for="sub in item.items"
                         :key="sub.title"
                         link
                         @click="clicou_menu_tipo_data(sub.click)"
                        >

                          <v-list-item-content>
                              <v-list-item-title v-text="sub.title"></v-list-item-title>
                          </v-list-item-content>
                          
                          
                          <v-list-item-icon>
                            <v-icon :color="sub.icon_color" small> {{ sub.icon }}</v-icon>
                          </v-list-item-icon>

                        </v-list-item>
                    </v-list-group>



                </v-list>
              </v-card>

          </v-menu>
        </v-col>

        <v-col cols="9">
          <v-select v-model="filtros.tipo_data" label="Tipo de Data"
          :items="items_data"
          item-value='CODIGO'
          item-text='DESCRICAO'
          dense
          @change="open_contas(di,df)"
          hide-details=""
          :readonly="!pode_alterar()"
          >
          <!-- :items="['EFETUADO','PREVISÃO','ATRASADO','LANÇADOS','[TODOS]','A RECEBER', 'A PAGAR']" -->
          <!-- :background-color="cor_tipo_data()" -->

          <template v-slot:selection="{ item }">
            <span :class="cor_tipo_data('X','cor') + ' text-body-2'">{{ item }}</span>
          </template>


          </v-select>
        </v-col>


        <v-col cols="1" class="px-0 pl-0" v-if="pode_alterar()">
          <v-menu
            bottom
            right
            min-width="180"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon  
                v-bind="attrs"
                v-on="on"
                color="blue"
                size="38"
                class=""
              >
                <!-- mdi-dots-vertical -->
                mdi-chevron-down 
              </v-icon> 
            </template>

            <v-list dense>
              
              <v-divider class="mx-0"></v-divider>


              <v-list-item
                v-for="(item, i) in menu_datas"
                :key="i"
                @click="clicou_menu_datas(item.click)"
              >
                <v-list-item-icon>
                  <v-icon :color=item.icon_color> {{ item.icon }} </v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

      </v-row>
    </v-col>



    <!-- COLUNA 2 -->
    <v-col :class="[(isMobile)? '': ' pl-5', 'ml-0 white lighten-4']"
       cols="12"   
       md="10" 
       lg="7" 
       xl="8" 
    >

       <v-row>

        <v-col cols="12" >
          <v-row>
            <!-- MOSTRA DIA -->
            <v-col v-if="(tipo_dia==0)" class="ml-0 mb-n2 v-text-field_menor"
              cols="6"
              md="3"
              lg="2"
            >          
              <v-row dense>
                <v-col class="">
                  <v-text-field
                    dense
                    v-model="in_today"
                    :label="cor_tipo_data('X','label')"
                    hide-details
                    type="date"
                    @input="update_in_today"
                  ></v-text-field>    
                </v-col>

                <v-col class="mb-n2" cols="12">
                  <div class="mt-n2 pb-n2 ml-n1" v-if="pode_alterar()">
                      <span class="ml-n0">
                        <v-icon  
                          color="blue"
                          size="22"
                          class=""
                          @click="avanca_data('v','d',in_today)"
                        >
                          mdi-chevron-double-left
                        </v-icon> 
                      </span>

                      <span class="ml-2">
                        <v-icon  
                          color="blue"
                          size="22"
                          class=""
                          @click="avanca_data('a','d',in_today)"
                        >
                          mdi-chevron-double-right
                        </v-icon> 
                      </span>
                  </div>
                </v-col>

              </v-row>  
          
            </v-col> 


            <!-- MOSTRA MES -->
            <v-col  v-if="(tipo_dia==1)" class="pb-0 ml-0"
                cols="6"
                md="3"
                lg="2"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date_m"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      v-model="date_m"
                      label="Mês"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details=""
                    >
                      <template v-slot:append>
                        <v-icon  color="blue">mdi-calendar</v-icon>
                      </template>
                  </v-text-field>
                  
                  <v-col class="mb-n2 pb-2 pt-0">
                  <div class="" >
                      <span class="ml-n4">
                        <v-icon  
                          color="blue"
                          size="22"
                          class=""
                          @click="avanca_data('v','m',date_m)"
                        >
                          mdi-chevron-double-left
                        </v-icon> 
                      </span>

                      <span class="ml-2">
                        <v-icon  
                          color="blue"
                          size="22"
                          class=""
                          @click="avanca_data('a','m',date_m)"
                        >
                          mdi-chevron-double-right
                        </v-icon> 
                      </span>
                  </div>
                </v-col>


                  </template>
                  <v-date-picker
                    v-model="date_m"
                    type="month"
                    no-title
                    scrollable
                    locale="pt-BR"
                  >
                  <!-- locale="br" -->
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="month_select($refs); $refs.menu.save(date_m);"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
            </v-col> 



            <!-- MOSTRA PERIODO -->
            <!-- de -->
            <v-col v-if="(tipo_dia==2)" :class="[(isMobile)? 'ml-0': '', 'white lighten-4']"
              cols="6"
              md="2"
              lg="2"
            >            
              <v-text-field label="De" class="v-text-field_menor"
                dense
                v-model="di"
                hide-details
                type="date"
                @input="open_contas(di,df)"
              ></v-text-field>
            </v-col> 

            <!-- ate -->
            <v-col
              cols="6"
              md="2"
              lg="2"
              v-if="(tipo_dia==2)"
              :class="[(isMobile)? 'ml-0': '', 'white lighten-4']"
            >            
              <v-text-field label="Até" class="v-text-field_menor"
                dense
                v-model="df"
                hide-details
                type="date"
                @input="open_contas(di,df)"
              ></v-text-field>
            </v-col> 


            <!-- conta referente -->
            <v-col v-if="(mostrar_filtros)"
              cols="6"
              md="2"
              lg="2"
            >
                <v-select v-model="filtros.conta" 
                  label="Conta Referente"
                  :items="items_tabela_contas_all"
                  item-value='CODIGO'
                  item-text='DESCRICAO'
                  return-object
                  dense
                  @change="open_contas(di,df)"
                  hide-details=""
                >
                </v-select>
            </v-col>

            <!-- tipo -->
            <v-col class="pb-0" v-if="(mostrar_filtros)"
              cols="6"
              md="2"
              lg="2"
            >   
                <v-select v-model="filtros.tipo" label="Tipo"
                  :items="items_tipo"
                  item-value='CODIGO'
                  item-text='DESCRICAO'
                  dense
                  @change="open_contas(di,df)"
                >
                </v-select>
            </v-col>


            <!-- categoria -->
            <v-col v-if="(mostrar_filtros)"
              cols="6"
              lg="3"
              md="3"
              class=""
            >
               <v-combobox
                  v-if="(categoria_forma)"
                  v-model="filtros.categoria"
                  label=""
                  class=""
                  :items="items_tabela_categoria"
                  item-value='CATEGORIA'
                  item-text='CATEGORIA'
                  :return-object="false"
                  dense
                  @input="insere_texto_lista('items_tabela_categoria', filtros.categoria)"
                  @blur="insere_texto_lista('items_tabela_categoria', filtros.categoria)"
                  @change="open_contas(di,df)"
                ></v-combobox>

               <v-combobox
                  v-else
                  v-model="filtros.forma"
                  label=""
                  class=""
                  :items="items_tabela_formas"
                  item-value='DESCRICAO'
                  item-text='DESCRICAO'
                  :return-object="false"
                  dense
                  @change="open_contas(di,df)"
                ></v-combobox>
                <!-- @keyup.esc="filtros.categoria='TODOS'" -->

                <div class="mt-n16">
                  <v-checkbox
                          v-model="categoria_forma"
                          label="CATEGORIA"
                          dense
                          style="margin-left: -4px"
                          class="pa-0 mt-n16 ml-n2"
                          hide-details="true"
                      >
                      <!-- @click="(categoria_forma) ? filtros.categoria='TODOS':'' " -->
                      <template v-slot:label>
                        <span class="blue--text ml-n2" style="font-size:12px !important">
                          {{ (categoria_forma) ? 'Categoria / Referente' : 'Forma' }} 
                        </span>
                      </template>
                  </v-checkbox>
                </div>

            </v-col>



            <!-- procurar -->
            <v-col
              cols="6"
              lg="3"
              md="2"
              :class="[(clicou_procurar) ? '' : '']"
            >
                <v-text-field v-model="search_conta" 
                  label="Procurar"
                  dense
                  clearable
                  append-icon="mdi-magnify"
                  :hide-details="true"
                  @keyup.esc="search_conta=''"
                  @keyup="search_conta = search_conta.toLowerCase()"
                  @keypress.enter="enter_filtar()"
                  @keydown="Shift_V"
                  id="search_conta"
                  @click="clicou_procurar=true"
                  @blur="clicou_procurar=false"
                  hint="Shift + V (vistar)"
                >
                </v-text-field>
            </v-col>

           </v-row>
        </v-col>
       </v-row>
      
    </v-col>
    
    
    <!-- COLUNA FILTROS -->
    <v-col class="pl-3 mt-n4" cols="4" v-if="(isMobile)">
      <v-switch
        dense
        v-model="mostrar_filtros"
        label="Filtros"
        hide-details="true"
        >
        </v-switch>
    </v-col>

    <v-spacer></v-spacer>
    

    <!-- COLUNA 3 -->
    <v-col 
      cols="8"
      md="12" 
      lg="3" 
      xl="2" 
      :class="[ (!isMobile)? 'pt-2':'' ,'pa-0 pr-4 text-right']"
    >
    <!-- lg="3 offset-lg-1"  -->



    <!-- <v-spacer></v-spacer> -->
    <!-- <span v-if="pode_alterar('limitado')"> -->
    <span v-if="pode_alterar('dre')">
       
      <v-menu
        bottom
        left
        min-width="180"
      >
        <template v-slot:activator="{ on, attrs }">

          
            <v-icon
              large
              color="primary"
              class="mr-4"
              v-bind="attrs"
              v-on="on"
            >
              mdi-menu
            </v-icon>
            <span  class="count_item fs-14 bold  ml-n5 mr-2"> {{menu_header.length}} </span>
         


        </template>

        <v-list dense>


          <v-subheader class="grey lighten-3">
            <h4 class="ml-1"> Opções: </h4>
          </v-subheader>

          <v-divider class="mx-0"></v-divider>


          <v-list-item
            v-for="(item, i) in menu_header"
            :key="i"
            @click="clicou_menu_header(item.click)"
          >
            <v-list-item-icon>
              <v-icon :color=item.icon_color> {{ item.icon }} </v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              {{ item.title }}
              <span  class="count_item fs-14 bold" v-if="(item.new=='S')"> 1 </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>



      <v-btn
        color="primary"
        @click="open_contas(di,df,'S'), status_zap(), saldo_asaas(), dialog_dre=true"
        x-small
        class="mr-4"
      >
        Atualizar
      </v-btn>


      <v-btn
        color="primary"
        @click="atualizaSelecao()"
        small
        class="mr-4"
        v-if="(0>1)"
      >
        SELECIONAR
      </v-btn>
        
      
    <span v-if="pode_alterar('limitado')">
    <v-tooltip 
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          class="mr-4"
          @click="adicionar('E')"
          x-small
          color="success"
        >
        <!-- v-show="!hidden" -->
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span> Nova Entrada </span>
    </v-tooltip>
    </span>


    <span v-if="pode_alterar('limitado')">
    <v-tooltip 
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"

          class=""
          @click="adicionar('S')"
          color="error"
          x-small
          fab
        >
        <!-- v-show="!hidden" -->
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span> Nova Saída </span>
    </v-tooltip>
    </span>

    </v-col>


  </v-row>
  </v-container>
</v-card>

<!-- DSi -->
<!-- {{ edita_parcelas }} -->
<!-- {{ item_menu }} -->
<!-- {{ filtros }} -->
<!-- {{ selected }} -->


<!-- PAINEL 2 CENTRAL TABELA -->
<!-- <v-card class="mt-2 overflow-y-auto" height="auto"> -->
<v-card class="mt-2 overflow-y-auto" max-height="90vh">
<!-- <v-card class="mt-2"> -->
 <v-container>

  <!-- centrol -->
  <v-row>
    <!-- coluna data-table principal-->
    <v-col 
    v-if="(ocultar_quando('basico'))"
    :cols="(value_expansion>=0) ? '2' : '11'" 
    :md="(value_expansion>=0) ? '9' : '11'" 
      class="pa-1">
      <v-row class="grey lighten-3 pa-0">
        <v-col>

          
          <!-- v-if="(pode_mostrar)" -->
          <v-data-table
                v-model="selected"
                item-key="CODIGO"

                @contextmenu:row="rightClick"
                @click:row="seleciona_linha"


                ref="data_tabble_1"
                id="data_tabble_1"
              
                
                :show-select="true"
                :single-select="false"
                
                :headers="header_conta"
                :items="items_conta"
                class="elevation-4"
            
                fixed-header
                dense
                :mobile-breakpoint="55"
                :search="search_conta"
                
                :item-class= "(agenda_colorida) ? rowClass : ''" 


                :items-per-page="-1"

                :hide-default-footer = "true"
                disable-pagination


                :height="retorna_altura( (isMobile) ? ((mostrar_filtros)? 500 : 400  ) :  (mostrar_rodape) ? 228 : 190)"
                :custom-filter="SearchNoVisibles"
                :custom-sort="customSort" 
                
            >


                <!-- 
                  //isso daqui que estava pesando quando eu digitava no campo e travava a digitação
                  :footer-props="{
                'items-per-page-options': [50,100,200,-1]
                }" -->




            <!-- :height="(isMobile) ? '' :  retorna_altura( (mostrar_rodape) ? 250 : 212)" -->
            <!-- @item-selected="esta_selecionando" -->
                    

                
                <template v-slot:item.CONTA_REFERENTE="{ item }">
                    <span class="fs-11"> 
                      {{ item.CONTA_REFERENTE }} 
                      <!-- {{ kkk }} -->
                      <!-- arrumar isso daqui, talvez fazer um selected 2 para atualizar somente quando necessario e nao toda hora -->
                    </span>
                </template>
              

                <template v-slot:item.SERVICO_REFERENTE="{ item, index }">

                    <v-chip v-if="item.X_NOME_CLIENTE" 
                      :color="(item.PAGO=='S')? 'green lighten-3' : (item.DATA_VENCIMENTO<today) ? 'red lighten-2' : 'blue lighten-3'" 
                      small class="ma-1 font-weight-medium"
                      @click="abre_menu(item)"
                    >
                      {{ (item.X_NOME_CLIENTE) }} &nbsp {{ item.CODIGO_CLIENTE }} 
                    </v-chip>

                    <v-chip 
                      :color="(item.TIPO=='E') ? 'green lighten-3' : 'red lighten-3'"
                      small class="font-weight-medium ma-1" v-if="(item.CATEGORIA)&&(item.CATEGORIA!='RECEBIMENTO')&&(item.CATEGORIA!='RECEBIMENTOS')"
                      @click="abre_menu(item)"
                    >

                    <!-- <v-icon left small color="white"> 
                        mdi-minus-circle
                      </v-icon> -->
                    
                      {{ item.CATEGORIA }} 
                      <!-- <span v-if="(item.OPERACAO)" class="pl-1"> ( {{ item.OPERACAO }} ) </span> -->
                    </v-chip>

                    <v-chip  small class="font-weight-medium ma-1"
                    :color="(item.CATEGORIA)&&(item.CATEGORIA!='RECEBIMENTO')&&(item.CATEGORIA!='RECEBIMENTOS') ? 'grey lighten-2' : (item.TIPO=='E') ? 'green lighten-4' : 'red lighten-3'"
                    v-if="(item.CATEGORIA!=item.SERVICO_REFERENTE)"
                    >
                      {{ item.SERVICO_REFERENTE }} 
                    </v-chip>

                    <span class="fs-10"> {{ item.PLANO }} </span>

                    <v-chip v-if="item.X_NOME_ALUNO" color="blue lighten-3" small class="font-weight-medium ma-1"
                    @click="abrir_cadastro(item.CODIGO_ALUNO, true)"
                    >
                      {{ (item.X_NOME_ALUNO) }} &nbsp {{ item.CODIGO_ALUNO }}
                    </v-chip>
                    
                    <span v-if="(item.OBSERVACAO && item.OBSERVACAO.length > 3) && (item.OBSERVACAO != item.X_NOME_ALUNO) && (item.OBSERVACAO != item.X_NOME_CLIENTE)"> 
                       <h5 class="ml-0 py-1 orange--text" v-if="item.OBSERVACAO">
                            <v-icon
                              v-if="item.OBSERVACAO !== ''"
                              class="ml-1"
                              size="14"
                              color="orange"
                            >
                               mdi-comment-alert 
                          </v-icon>
                            ({{ item.OBSERVACAO }})
                        </h5>
                    </span>

                </template>

                <template v-slot:item.CODIGO="{ item, index }">
                  <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0" v-if="(!souDSi)"> {{ index + 1 }}º &nbsp </span>  

                  <span style=" text-align: left; font-size: 12px;" class="grey--text pa-0 mt-2 ml-0" > {{ item.CODIGO }} </span>  
                  <!-- <span>{{ (item.CODIGO) }}</span> -->
                </template>

                <template v-slot:item.VISTO="{ item, index }">
                  <div @click="muda_visto(item, true)">
                    <v-icon small color="green" v-if="(item.VISTO=='S')">
                      mdi-check-bold 
                    </v-icon>
                    <v-icon small  v-else>
                      mdi-radiobox-blank 
                    </v-icon>
                </div>
                </template>

                <template v-slot:item.PIX_URL="{ item }">
                    <a 
                    :href="item.PIX_URL"   
                    target="_blank"
                    >
                      {{ item.PIX_URL }}
                    </a>

                </template>

                <template v-slot:item.x_DATA_VENDA="{ item }">
                    <span 
                    class="green--text font-weight-bold"
                    >
                      {{(item.x_DATA_VENDA) }}
                    </span>
                </template>

                <template v-slot:item.x_DATA_VENCIMENTO="{ item }">
                    <span 
                    :class="cor_tipo_data('V','cor')"
                    >
                      {{ (item.x_DATA_VENCIMENTO) }}
                    </span>
                </template>


                <template v-slot:item.x_DATA_EFETUADO="{ item }">
                    <span 
                    :class="cor_tipo_data('E','cor')"
                    v-if="(item.x_DATA_EFETUADO!='//') && (item.x_DATA_EFETUADO!='00/00/0000')">
                      {{ (item.x_DATA_EFETUADO) }}
                    </span>
                    <!-- class="font-weight-bold" -->
                </template>

                <template v-slot:item.double_TOTAL="{ item }">

                  <span v-if="(strtofloat(item.double_TAXA)>0) || (strtofloat(item.double_JUROS)>0)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                        :class="[strtofloat(item.double_TAXA)>0 ? ' bold ': ' font-weight-medium ',  getCorTipo(item.TIPO)]"
                        v-bind="attrs" v-on="on"
                        >
                          R$ {{ item.double_TOTAL }}*
                        </span>

                      </template>
                          <div>  + Juros: {{ (item.double_JUROS) }} </div>
                          <div>  - Taxa: {{ (item.double_TAXA) }} </div>
                    </v-tooltip>
                  </span>  

                  <span
                    v-else
                    :class="[strtofloat(item.double_TAXA)>0 ? ' bold ': ' font-weight-medium ',  getCorTipo(item.TIPO)]"
                    >
                      R$ {{ item.double_TOTAL }} 
                    </span>



                </template>


                <template v-slot:item.double_VALOR="{ item }">
                    <span :class="[(item.TIPO == 'S') ? 'red--text': 'black--text', 'font-weight-medium']">
                      R$ {{ item.double_VALOR }}
                    </span>
                </template>


                <template v-slot:item.actions="{ item, index}">
                    <v-icon
                        class="mr-2"
                        @click="abre_menu(item)"
                        color="blue"
                    >
                    mdi-menu
                    </v-icon>
                    <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>
                </template>

                <template v-slot:item.?="{ item, index }">
                    <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>
                    
                    <v-icon class="pr-1" size="15" color="black" v-if="item.PIX_URL"> mdi-barcode </v-icon>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pr-1" size="15"  v-if="item.ENVIOU_EMAIL" 
                          :color="(item.ENVIOU_EMAIL=='V') ? 'blue' : (item.ENVIOU_EMAIL=='A')  ? 'red' : (item.ENVIOU_EMAIL=='A2')  ? 'red' : (item.ENVIOU_EMAIL=='H')? 'orange' : ''"
                          v-bind="attrs"
                          v-on="on"                  
                        > mdi-email-arrow-right 
                      </v-icon>
                      </template>
                      <span v-text="(item.ENVIOU_EMAIL=='V') ? 'Aviso de Vencimento' : (item.ENVIOU_EMAIL=='A')  ? 'Aviso de Atrasado 5 dias' : (item.ENVIOU_EMAIL=='A2')  ? 'Aviso de Atrasado 10 dias' : (item.ENVIOU_EMAIL=='H') ? 'Aviso de Hoje' : ''">  </span>
                      <!-- <span v-text="(dias_dif_aqui == 0) ? 'hoje':'dia '+ dia_vence_aqui">  </span> -->
                    </v-tooltip>


                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        
                        <v-icon  size="15"  v-if="item.ENVIOU_WHATS"
                          :color="(item.ENVIOU_WHATS=='V') ? 'blue' : ((item.ENVIOU_WHATS=='A')||(item.ENVIOU_WHATS=='A2'))  ? 'red' : (item.ENVIOU_WHATS=='H')? 'orange' : (item.ENVIOU_WHATS=='T')? 'purple' : (item.ENVIOU_WHATS=='X')? 'black' :  ''"
                          v-bind="attrs"
                          v-on="on"
                        > mdi-whatsapp </v-icon>
                      </template>
                      <span> {{  item.WHATS_STATUS  }} </span>
                    </v-tooltip>

                    <!-- <v-icon color="" v-else-if="item.PIX_URL"> mdi-qrcode </v-icon> -->
                </template>
            
            </v-data-table>

        </v-col>

      </v-row>
    </v-col>

    <!-- coluna lateral direita -->
    <v-col 
    :cols="(value_expansion>=0) ? '10' : '1'"
    :md="(value_expansion>=0) ?  3 : '1'"
     class="py-0">
      <v-row class="grey lighten-3 pa-0">
        <v-col class="px-1">

          <v-card 
            flat
            elevation="5"
            class="mt-1 grey lighten-3 mb-4"
            >
              <v-expansion-panels 
                :multiple="false"
                :focusable='false'
                v-model="value_expansion"
                >

                <!-- WHATSAPP -->
                <v-expansion-panel v-if="(souDSi) && pode_alterar()">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="minhas_tarefas(),expansion_selecionao='WHATSAPP'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="primary"> 
                              mdi-whatsapp
                            </v-icon> 
                            
                            <div class="fs-14">WhatsApp
                            </div>
                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->
                      <v-container>

                        <v-row>
                          <v-col class="px-0 py-0 red ">

                            <v-fab-transition>
                              <v-btn
                                v-show="(value_expansion>=0)"
                                color="primary"
                                dark
                                absolute
                                right
                                fab
                                x-small
                                @click="abre_janela_modal(true, true, 'WHATSAPP', 'WHATSAPP')"
                              >
                                <v-icon>mdi-menu</v-icon>
                              </v-btn>
                          </v-fab-transition>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- TAREFAS -->
                <v-expansion-panel v-if="2>1">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="minhas_tarefas(),expansion_selecionao='TAREFAS'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="primary"> 
                              mdi-clock-time-four
                            </v-icon> 
                            
                            <span v-if="items_tarefas.length>0" class="count_item fs-14 bold">{{ items_tarefas.length }}</span>
                            <div class="fs-14">Tarefas </div>
                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->
                      <v-container>

                        <v-row>
                          <v-col class="px-0 py-0 red ">

                            <v-fab-transition>
                              <v-btn
                                v-show="(value_expansion>=0)"
                                color="primary"
                                dark
                                absolute
                                right
                                fab
                                x-small
                                @click="abre_janela_modal(true, false, 'TAREFAS', 'TAREFAS')"
                              >
                              <!-- @click="janela_modal=true" -->

                                <v-icon>mdi-menu</v-icon>
                              </v-btn>
                          </v-fab-transition>

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, index) in items_tarefas"
                                  :key="index"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>

                                          <!-- <v-col cols="4">
                                            <span>
                                              <h5 style="display: inline; font-size: 12px;" :class="[(item.TIPO=='S')?'red--text' : 'green--text' , 'ml-0 pl-1']"> 
                                               {{ getFormatCurrency(item.TOTAL,'N') }}
                                              </h5>
                                            </span>
                                          </v-col> -->

                                          <v-col>
                                            <span style="display: inline; font-size: 8px;">{{index+1 }}</span>

                                            <span>
                                              <h5 style="display: inline; font-size: 12px;" class="red--text"> {{ item.DESCRICAO }} </h5>  
                                            </span>
                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- FORMAS -->
                <v-expansion-panel v-if="(ocultar_quando('basico'))">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="estatisticas_formas(),expansion_selecionao='FORMAS'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="30" class="pr-1" color="primary"> 
                              mdi-chart-line-variant 
                              <!-- mdi-poll  -->
                              <!-- mdi-credit-card-check -->
                            </v-icon> 
                            
                            <span  class="count_item fs-14 bold"> 
                                {{(value_expansion>=0) ? items_estat_formas.length : '1'}}
                            </span>

                            <div class="fs-14">Formas 

                            </div>

                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->

                      <v-container>
                        <v-row>
                          <v-col class="px-0 py-0 red ">

                          <v-list
                            dense
                            style="max-height: 222px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, i) in items_estat_formas"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>

                                          <v-col cols="4">
                                            <span>
                                              <h5 style="display: inline; font-size: 12px;" :class="[(item.TIPO=='S')?'red--text' : 'green--text' , 'ml-0 pl-1']"> 
                                               {{ getFormatCurrency(item.TOTAL,'N') }}
                                              </h5>
                                            </span>
                                          </v-col>

                                          <v-col>
                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" class="ml-1"
                                              @dblclick="filtra_search(item.FORMA_PAGAMENTO)"
                                              >
                                              <!-- @dblclick="filtra_search(item.FORMA_PAGAMENTO)" -->
                                              {{ item.FORMA_PAGAMENTO }} </h5>  
                                            </span>

                                            <span style="display: inline; font-size: 8px;">{{ item.QT }}</span>
                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>


                                <v-divider class="py-1"></v-divider>

                                <!-- SOMATORIA -->
                                <v-list-item
                                  v-for="(item, i) in items_somatorias"
                                  :key="i+99"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0 pl-1">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <p style="display: inline" class=" text-caption black--text">  {{ item.QT }} </p> -->
    
                                        <v-row>
                                          <v-col cols="3">
                                            <span>
                                              <h5 
                                               :class="[(item.CAMPO=='ENTRADA')?'green--text' : (item.CAMPO=='SAIDA')?'red--text': 'blue--text' , 'fs-12']" style="display: inline"
                                              > 
                                                {{ item.CAMPO }} 
                                              </h5>
                                            </span>

                                          </v-col>
                                          
                                          <v-col cols="6" class="px-1" >
                                              <h5 
                                              :class="[(item.CAMPO=='ENTRADA')?'green--text' : (item.CAMPO=='SAIDA')?'red--text': 'blue--text' , 'fs-12']" style="display: inline"
                                              >
                                                {{ getFormatCurrency(item.VALOR,'N') }}
                                              </h5>
                                          </v-col>
                                          
                                        </v-row>

                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />
                                  </v-list-item-content>
                                </v-list-item>


                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>



                <!-- CATEGORIA -->
                <v-expansion-panel v-if="(ocultar_quando('basico'))">
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="estatisticas_categoria(),expansion_selecionao='CATEGORIA'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="30" class="pr-1" color="primary"> 
                              mdi-chart-line-variant 
                              <!-- mdi-chart-line  -->
                              <!-- mdi-poll  -->
                              <!-- mdi-credit-card-check -->
                            </v-icon> 
                            
                            <span  class="count_item fs-14 bold"> 
                                {{(value_expansion>=0) ? items_estat_categoria.length : '1'}}
                               </span>

                            <div class="fs-14">Categorias 
                               
                            </div>

                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->

                      <v-container>


                  <v-row class="mt-1 px-0">
                      <v-col class="pa-0  mt-n4 mb-1 " cols="12">
                        <v-checkbox
                          v-model="est_grupo_agrupado"
                          label="Label"
                          dense
                          hide-details
                          class="py-0 ma-0"
                          @click="estatisticas_categoria()"
                        >
                          <template v-slot:label>
                            <span :class="['fs-12']">Detalhado</span>
                          </template>
                        </v-checkbox>

                        <!-- <h5 class="ml-1 orange--text">Duplo clique abre Gráfico</h5> -->
                      </v-col>
                      </v-row>


                        <v-row>
                          <v-col class="px-0 py-0  ">

                          <v-list
                            dense
                            style="max-height: 322px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border">

                                <v-list-item
                                  v-for="(item, i) in items_estat_categoria"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <v-list-item-content class="py-0">
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <v-row>

                                          <v-col cols="4" class="">

                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                  class="px-1"
                                                  color="blue"
                                                  size="20"
                                                  @click="grafico_categorias(item, (est_grupo_agrupado)? item.NOME : item.CATEGORIA, 5, item.TIPO)"

                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  mdi-chart-line-variant
                                                </v-icon>
                                              </template>
                                              <span>  Mostrar Gráfico </span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                  class="px-1"
                                                  color="blue"
                                                  size="20"
                                                  @click="lista_categorias((est_grupo_agrupado)? item.NOME : item.CATEGORIA)"

                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                mdi-format-list-bulleted
                                                </v-icon>
                                              </template>
                                              <span>  Filtrar esses Lançamentos </span>
                                            </v-tooltip>

                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" :class="[(item.TIPO=='S')?'red--text' : 'green--text' , 'ml-0 pl-1']"> 
                                               {{ getFormatCurrency(item.TOTAL,'N') }}
                                              </h5>
                                            </span>
                                          </v-col>

                                          <v-col class="">

                                            <span>
                                              <h5 style="display: inline; font-size: 10px;" class="ml-0"> {{(est_grupo_agrupado)? item.NOME : item.CATEGORIA }} </h5>  
                                            </span>

                                            <span style="display: inline; font-size: 8px;">{{ item.QT }}</span>
                                          </v-col>
                                        </v-row>
                                      </div>

                                      <v-divider class="mx-0"/>
                                  </v-list-item-content>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- ATRASADAS -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="estatisticas_atrasadas(2),expansion_selecionao='ATRASADAS'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="22" class="pr-1" color="red"> 
                              mdi-poll 
                              <!-- mdi-chart-timeline-variant -->
                              <!-- mdi-chart-bubble -->
                            </v-icon> 
                            <span  class="count_item fs-14 bold"> {{count_atrasadas}} </span>
                            <span class="bold ml-1 fs-14">Atrasadas</span>
                            <span class="ml-3 bold fs-12" v-if="(value_expansion>=0)">Últimos 2 meses</span>
                            
                            
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->

                      <v-container>

                      <v-row class="mt-1 px-0" v-if="(2>1)">

                        <v-col class="pa-0 mt-n4 mb-2 " cols="7">


                          <v-chip  class="ml-1 hand"
                          :color="(zapzap_conectado) ? 'success' : 'primary'"
                          @click="disparar_whats_parcelas('ATRASADAS')"
                          >
                            {{(zapzap_conectado)?'Enviar' : 'Conectar'}}
                            <span class="mt-n2 ml-1"> {{ (atrasadas_model.length) || (items_estat_atrasadas.length) }} </span>

                            <v-icon right >
                              mdi-whatsapp
                            </v-icon>
                          </v-chip>



                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
              
                              <v-icon class="ml-4" color="primary" size="20"
                                  @click="alerta(`<div style='text-align: left;'> Clique em: <br/>  <i class='mdi mdi-cogs'></i> Configurações <br/> <i class='mdi mdi-whatsapp'></i> Mensagens do WhatsApp </div>`,'black', 5000)"
                                  v-bind="attrs" v-on="on"
                                  >
                                  <!-- @click="alerta(`Clique em: <br/> <i class='mdi mdi-cogs'></i> Configurações,  <i class='mdi mdi-whatsapp'></i> Mensagens do WhatsApp`,'black', '5000',5000)" -->
                                    mdi-pencil
                                  </v-icon>

                            </template>
                            <span> Alterar Mensagem </span>
                          </v-tooltip>


                        </v-col>

                      </v-row>



                        <v-row>
                          <v-col class="px-0 py-0  ">

                          <v-list
                            dense
                            style="max-height: 280px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border"
                                v-model="atrasadas_model" 
                                multiple
                              >

                                <v-list-item
                                  v-for="(item, i) in items_estat_atrasadas"
                                  :key="i"
                                  :value="item"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                    <template v-slot:default="{ active }">
                                      <v-list-item-content class="py-0">
                                          <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                            <v-row :class="[(item.WHATS_ATRASADO=='S') ? 'green lighten-5' : '']">


                                              <v-col cols="1" class="mt-n2 ">
                                                <v-checkbox
                                                    :input-value="active"
                                                    color="deep-purple accent-4"
                                                    dense
                                                    hide-details
                                                  ></v-checkbox>
                                              </v-col>

                                              <v-col cols="10" class="">
                                                <!-- @click.stop="" -->

                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">

                                                    <span style="display: inline; font-size: 12px;" class="ml-0 pl-0 mr-2"
                                                      v-if="(item.WHATS_ATRASADO=='S')"
                                                      > 
                                                          <v-icon small color="green">
                                                            mdi-check-bold 
                                                          </v-icon>
                                                    </span>

                                                    <v-icon 
                                                      class="pl-0 "
                                                      color="green"
                                                      size="20"
                                                      @click="before_enviar_whats(item, 'ATRASADAS', false)"
                                                      :disabled="(!item.CELULAR)"
                                                      v-if="(item.CODIGO_ALUNO>0)"

                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                    <!-- v-if="(item.CODIGO_ALUNO>0) && pode_alterar('limitado')" -->
                                                      mdi-whatsapp
                                                    </v-icon>


                                                  </template>
                                                  <p>  Enviar Aviso Manualmente </p>
                                                  <span>  {{ item.CELULAR }} </span>
                                                </v-tooltip>


                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">

                                                    <h5 style="display: inline !important; font-size: 9px;" class=""
                                                    v-bind="attrs"
                                                        v-on="on"
                                                    > {{ item.x_DATA_VENCIMENTO }} </h5>  
                                                
                                                  </template>
                                                  <span> Vencimento </span>
                                                  <h5> CODIGO: {{item.CODIGO}} </h5>
                                                  <span>  {{ item.SERVICO_REFERENTE }} </span>

                                                </v-tooltip>


                                                <span style=" text-align: right; font-size: 8px;" class="grey--text "> {{ i + 1 }}º  </span>
                                                

                                                <span>
                                                  <h5 style="display: inline; font-size: 10px;" class="ml-0 mr-2 pl-1 red--text"> 
                                                  {{ getFormatCurrency(item.TOTAL,'N') }}
                                                  </h5>
                                                </span>



                                                <span v-if="(item.CODIGO_ALUNO>0)">
                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                      class=""
                                                      color="primary"
                                                      size="18"
                                                      @click="(item.CODIGO_ALUNO) ? abrir_cadastro(item.CODIGO_ALUNO, true) : ''"

                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                      mdi-account
                                                    </v-icon>

                                                  </template>
                                                  <span v-if="(item.CODIGO_ALUNO)">
                                                    <p>  IDE: {{(item.CODIGO_ALUNO) || (item.CODIGO_CLIENTE)}}</p>
                                                    <span> Abrir Cadastro </span>
                                                  </span>
                                                </v-tooltip>
                                              </span>

                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">

                                                    <h5 style="display: inline !important; font-size: 10px;" class="ml-0 primary--text" v-bind="attrs" v-on="on">
                                                    {{ item.NOME }} </h5>
                                                
                                                  </template>
                                                  <h5>  {{ item.CELULAR }} </h5>
                                                </v-tooltip>

                                              </v-col>

        
                                            </v-row>
                                          </div>

                                          <v-divider class="mx-0"/>
                                      </v-list-item-content>
                                    </template>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- PREVISAO -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="estatisticas_previsao(2),expansion_selecionao='PREVISAO'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="22" class="pr-1" color="green"> 
                              mdi-poll 
                              <!-- mdi-chart-timeline-variant -->
                              <!-- mdi-chart-bubble -->
                            </v-icon> 
                            <span  class="count_item fs-14 green--text bold"> {{count_previsao}} </span>
                            <span class="bold ml-1 fs-14">Previsão</span>
                            <span class="ml-3 bold fs-12" v-if="(value_expansion>=0)">Próximos 3 dias</span>
                            
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->

                      <v-container>


                        <v-row class="mt-1 px-0" v-if="(2>1)">

                          <v-col class="pa-0 mt-n4 mb-2 " cols="7">


                            <v-chip  class="ml-1 hand"
                            :color="(zapzap_conectado) ? 'success' : 'primary'"
                            @click="disparar_whats_parcelas('PREVISAO')"
                            >
                             {{(zapzap_conectado)?'Enviar' : 'Conectar'}}
                              <span class="mt-n2 ml-1"> {{ (previsao_model.length) || (items_estat_previsao.length) }} </span>

                              <v-icon right >
                                mdi-whatsapp
                              </v-icon>
                            </v-chip>


                            <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
              
                              <v-icon class="ml-4" color="primary" size="20"
                                  @click="alerta(`<div style='text-align: left;'> Clique em: <br/>  <i class='mdi mdi-cogs'></i> Configurações <br/> <i class='mdi mdi-whatsapp'></i> Mensagens do WhatsApp </div>`,'black', 5000)"
                                  v-bind="attrs" v-on="on"
                                  >
                                  <!-- @click="alerta(`Clique em: <br/> <i class='mdi mdi-cogs'></i> Configurações,  <i class='mdi mdi-whatsapp'></i> Mensagens do WhatsApp`,'black', '5000',5000)" -->
                                    mdi-pencil
                                  </v-icon>

                            </template>
                            <span> Alterar Mensagem </span>
                          </v-tooltip>

                            <!-- <v-btn
                              class="ma-1"
                              color="green"
                              x-small dark
                              
                              @click="disparar_whats_parcelas('PREVISAO')"
                            >
                              enviar ({{ (previsao_model.length) || (items_estat_previsao.length) }})

                              <v-icon right small>
                                mdi-whatsapp
                              </v-icon>
                            </v-btn> -->
                          </v-col>

                        </v-row>


                        <v-row>
                          <v-col class="px-0 py-0  ">

                          <v-list
                            dense
                            style="max-height: 322px"
                            class="overflow-y-auto px-0 py-0"
                            >
                              <v-list-item-group active-class="border"
                                v-model="previsao_model" 
                                multiple
                              >

                                <v-list-item
                                  v-for="(item, i) in items_estat_previsao"
                                  :key="i"
                                  :value="item"
                                  class="px-0 py-0 grey lighten-5"
                                  style="min-height: 20px !important;"
                                  
                                >

                                  <template v-slot:default="{ active }">
                                    <v-list-item-content class="py-0">
                                        <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                          <v-row :class="[(item.WHATS_LEMBRETE=='S') ? 'green lighten-5' : '']">

                                            <v-col cols="1" class="mt-n2 ">
                                                <v-checkbox
                                                    :input-value="active"
                                                    color="deep-purple accent-4"
                                                    dense
                                                    hide-details
                                                  ></v-checkbox>
                                              </v-col>

                                            <v-col cols="10" class="">


                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">

                                                  <span style="display: inline; font-size: 12px;" class="ml-0 pl-0 mr-2"
                                                    v-if="(item.WHATS_LEMBRETE=='S')"
                                                    > 
                                                        <v-icon small color="green">
                                                          mdi-check-bold 
                                                        </v-icon>
                                                  </span>

                                                  <v-icon 
                                                    class="pl-0"
                                                    color="green"
                                                    size="20"
                                                    @click="before_enviar_whats(item, 'PREVISAO', false)"
                                                    :disabled="(!item.CELULAR)"
                                                    v-if="(item.CODIGO_ALUNO>0) && pode_alterar('limitado')"

                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    mdi-whatsapp
                                                  </v-icon>

                                                </template>
                                                <p>  Enviar Aviso Manualmente</p>
                                                <span>  {{ item.CELULAR }} </span>
                                              </v-tooltip>



                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">

                                                  <h5 style="display: inline !important; font-size: 9px;" class=""
                                                  v-bind="attrs"
                                                      v-on="on"
                                                  > {{ item.x_DATA_VENCIMENTO }} </h5>  
                                              
                                                </template>
                                                <span> Vencimento </span>
                                                <h5> CODIGO: {{item.CODIGO}} </h5>
                                                <span>  {{ item.SERVICO_REFERENTE }} </span>
                                              </v-tooltip>


                                              <span style=" text-align: right; font-size: 8px;" class="grey--text "> {{ i + 1 }}º  </span>
                                              <!-- <span>
                                                <h5 style="display: inline !important; font-size: 10px;" class="ml-2 primary--text"> {{ item.NOME }} </h5>  
                                              </span> -->


                                              <span>
                                                <h5 style="display: inline; font-size: 10px;" class="ml-0 mr-2 pl-1 green--text"> 
                                                {{ getFormatCurrency(item.TOTAL,'N') }}
                                                </h5>
                                              </span>



                                              <span v-if="(item.CODIGO_ALUNO>0)">
                                              <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                      class=""
                                                      color="primary"
                                                      size="18"
                                                      @click="(item.CODIGO_ALUNO) ? abrir_cadastro(item.CODIGO_ALUNO, true) : ''"

                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                      mdi-account
                                                    </v-icon>

                                                  </template>
                                                  <span v-if="(item.CODIGO_ALUNO)">
                                                    <p>  IDE: {{(item.CODIGO_ALUNO) || (item.CODIGO_CLIENTE)}}</p>
                                                    <span> Abrir Cadastro </span>
                                                  </span>
                                                </v-tooltip>
                                               </span>

                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">

                                                  <h5 style="display: inline !important; font-size: 10px;" class="ml-0 primary--text" v-bind="attrs" v-on="on"
                                                  > {{ item.NOME }} </h5>
                                              
                                                </template>
                                                <h5>  {{ item.CELULAR }} </h5>
                                              </v-tooltip>

                                            </v-col>

      
                                          </v-row>
                                        </div>

                                        <v-divider class="mx-0"/>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>




                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </v-card>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
 </v-container>
</v-card>


<!-- PAINEL 3 BOTTOM -->
<!-- <v-footer class="ml-15 pa-0 red" fixed> -->
  <v-card class="mt-2" v-if="(ocultar_quando('basico'))">
  <v-container class="grey lighten-3 px-4 py-2">
    <v-row class="">

      <v-card height="20" class="my-3 ml-1 mb-4 grey lighten-3" elevation="0">
        <v-row dense>
          <v-col>
            <v-icon  
                color="blue"
                class=""
                size="33"
                @click="ocultar_rodape"
              >

               {{(!mostrar_rodape) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                
              </v-icon> 
          </v-col>
        </v-row>
      </v-card>

      <v-col 
        class=""
        cols="12"
        md="6"
        v-if="mostrar_rodape"
      >
        <v-row class="">  
              
            <v-col
              cols="4"
              md="3"
              class="px-2"
            >
              <v-card
                class="green"
                elevation="4"
              >


                <v-card-text class="pa-2">
                  <span class="white--text"> Entradas</span>
                  <span class="white--text text-size-4" style="font-size:18px">  {{ somas.c_entrada }}</span>                
                  <div class="">
                    <span class="white--text text-subtitle-2"> R$ {{ getFormatCurrency(somas.s_entrada,'N') }} </span>

                    <span v-if="(sum_entrada>0)" class="white--text text-subtitle-2" style="font-size:11px !important">({{getFormatCurrency( sum_entrada,'N') }})</span>
                  </div>
                  
                </v-card-text>
              </v-card>
            </v-col>  


            <v-col
              cols="4"
              md="3"
              class="px-2"
            >
              <v-card
                class="red"
                elevation="4"
              >
                <v-card-text class="pa-2">
                  <span class=" white--text">Saídas</span>
                  <span class="white--text text-size-4" style="font-size:18px">  {{ somas.c_saida }}</span>                
                  <div class="">
                    <span class="white--text text-subtitle-2"> R$ {{ getFormatCurrency(somas.s_saida) }} </span>

                    <span v-if="(sum_saida>0)" class="white--text text-subtitle-2" style="font-size:10px !important">({{getFormatCurrency(sum_saida,'N')  }})</span>

                  </div>
                </v-card-text>
              </v-card>
            </v-col>   


            <v-col
              cols="4"
              md="3"
              class="px-2"
            >
              <v-card
                class="blue"
                elevation="4"
              >
                <v-card-text class="pa-2">
                  <span class=" white--text">Saldo</span> 
                  <span class="white--text text-size-4" style="font-size:18px">  {{ somas.c_total }}</span>                
                  <div class="">
                    <span class="white--text text-subtitle-2"> R$ {{ getFormatCurrency(somas.s_total) }} </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>    

            <!-- saldo asaas -->
            <v-col
              cols="4"
              md="3"
              class="px-2"
              v-if="(plataforma_cobranca) && (this.pode_alterar())"
            >
            <!-- v-if="(souDSi)&&(plataforma_cobranca)" -->
              <v-card
                class="grey"
                elevation="4"
              >

              
              <v-card-text class="pa-2">
              
                <v-row dense>
                  <v-col cols="9" class="">
                    <a class=" white--text" @click="dialog_opcoes=true, send_valor=null" >
                       {{  plataforma_cobranca }}
                    </a> 
                    <div class="">
                      <a class="white--text text-subtitle-2" @click="dialog_opcoes=true, send_valor=getFormatCurrency(cobranca_sum)"> R$ {{ getFormatCurrency(cobranca_sum) }} </a>
                    </div>
                  </v-col>

                  <v-col cols="3" class="">
                    <span v-if="(souDSi)">
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary"
                            v-bind="attrs"
                            v-on="on"
                            :size="(zap_status ? '':'33')"
                            :color="(zap_status ? 'white':'yellow')"
                            @click="status_zap( (zap_status) ? '' : 'qrcode')"
                            >mdi-whatsapp</v-icon>

                        </template>
                        <span> {{(zap_message) }} </span>
                      </v-tooltip>
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>

              </v-card>

            </v-col>      
            
          </v-row>
      </v-col>

      <v-col class="grey" cols="12" md="6" v-if="0>1">
        <vrow>

        </vrow>

      </v-col>

    </v-row>
  </v-container>
</v-card>
<!-- </v-footer> -->


    <!-- MOSTRA JANELA MODAL -->
    <div class="mt-n10">
      <v-row>
        <v-col>
        <Editar_modal 
          :aberto = 'janela_modal_abre'
          :pro_tabela = 'janela_modal_tabela'
          :pro_titulo = 'janela_modal_titulo'
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'janela_modo_edicao'
          @muda_aberto_lembretes="janela_modal_change"
        />
        </v-col>
      </v-row>
    </div>



</div>
</template>

<script>

import axios from "axios";
import DSibasico, { alerta } from '../assets/js/DSi_basico'//chamo funcao do arquivo
import { send_whats, primeiro_nome, copiarParaClipboard , total_da_lista, exportVisibleToExcel} from '../assets/js/DSi_basico'
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { negritoO } from '../assets/js/DSi_sql'
import { logado } from '../assets/js/DSi_rotinas'
import { send_whats_api, status_whats } from '../assets/js/DSi_whats'
import { registra_log} from '../assets/js/DSi_login'



export default {

     components:{
       Editar_modal: () => import("./Editar.vue")
     },


    data: () => ({
      
      souDSi: false,

      obj_dados_empresa: null,


      dialog_ok: false,
      dialog_ok_tipo: '',

      zapzap_mostrar: false,
      zapzap_conectado: false,

      items_somatorias :[],

      atrasadas_model:[],
      previsao_model:[],

      chart_dialog: false,
      chart_color: '',
      chart_title: '',
      chart_values: [],
      chart_labels: [],
      chart_quero :[],
      chart_comparar: false,

      // chart_labels: [
      //   '0',
      //   '0',
      //   '0',
      //   '-8%',
      //   '-9%',
      //   '-12%',
      // ],
      // chart_values: [
      //   200,
      //   675,
      //   410,
      //   390,
      //   310,
      //   460,
      //   250,
      //   240,
      // ],

      items_chart: [
        { "MES": "2024-07", "QT": "16", "TOTAL": "2059.62", "x_echoDSi": "cfc_45" },
        { "MES": "2024-06", "QT": "12", "TOTAL": "2512.42", "x_echoDSi": "cfc_45" },
        { "MES": "2024-05", "QT": "7", "TOTAL": "2597.5", "x_echoDSi": "cfc_45" },
        { "MES": "2024-04", "QT": "17", "TOTAL": "42122.1", "x_echoDSi": "cfc_45" },
        { "MES": "2024-03", "QT": "7", "TOTAL": "4237.969999999999", "x_echoDSi": "cfc_45" },
        { "MES": "2024-02", "QT": "10", "TOTAL": "4819.38", "x_echoDSi": "cfc_45" },
        { "MES": "2024-01", "QT": "1", "TOTAL": "286.66", "x_echoDSi": "cfc_45" }
      ],

      last_parametros:{},

      pode_mostrar: false,

      expansion_selecionao:'',

      janela_modal_abre  : false,
      janela_modal_tabela: '',
      janela_modal_titulo: '',
      janela_modo_edicao : false,

      agenda_colorida: true,

      selectableItems:[],

      items_somatorias:[],
      sum_entrada:0,
      sum_saida:0,

      executa_esta_sql:'',

      est_grupo_agrupado: false,

      dados_parcial:null,

      count_atrasadas: 0,
      count_previsao: 0,
      items_tarefas :[],
      items_estat_counts :[],
      items_estat_atrasadas :[],
      items_estat_previsao :[],
      items_estat_categoria :[],
      items_estat_formas :[],

      value_expansion: -1, //0 ou -1 ou null

      comp_focus:'',

      mostrar_filtros: false,

      categoria_forma: true,
      check_juros_aqui: false,
      multiplicar: 1,
      lista_parcelas:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],

      obj_user: {},//dados do operador

      clicou_procurar: false,

      mostrar_rodape: true,

      acao_atual : '', 

      dialog_opcoes: false,
      send_valor: null,
      send_conta:{}, 

      config_emp: {},
      config_pix: {},
      config_whats: {},

      dialog_img: false,
      img_qrcode_zap: '',

      cobranca_sum:'',
      plataforma_cobranca:'',

      url_externo:'',
      dialog_qrcode: false,
      img_qrcode_zap: '',
      zap_status:'',
      zap_message:'',

      can_show: false,

      overlay: false,

      display_cobranca :'',

      showJuros: false,

      checkbox_efetuado: true,

      valido:false,

      isMobile: false, 

      items_data: [],

      menu_parcelas: [],

      menu_tipo_data: [],

      selected2: [],
      selected: [],
      selected_copia: [],
      selecionou_varios: false,

      menu_datas: [],
      menu_header: [],

      edita_parcelas:{}, 

      editedItem: {},

      items_tabela_categoria:[],
      items_tabela_operacao:[],

      dialog_alterar_parcela: false,
      dialog_menu: false,
      item_menu:{},
      item_menu_copia:{},
      item_clicou:{},


      filtros:{
        conta:0, tipo: '', tipo_data:'EFETUADO', categoria:'TODOS'
      },

      somas:{
        c_entrada:0, s_entrada:0, c_saida:0, s_saida:0, c_total:0, s_total:0
      },

      items_tabela_contas: [],
      items_tabela_contas_pix: [],
      items_tabela_contas_all: [],
      items_tipo:[],
      items_tabela_formas: [],

      header_conta: [],
      items_conta: [],
      search_conta:'',
      search_combobox:'',

      items_sum_conta:[],

      w_data: '99px',
      w_fone: '140px',
      w_cpf: '120px',
      w_nome: '200px',
      w_situacao: '60px',
      w_juros: '99px',
      w_valor: '99px',

      menu: false,
      tipo_dia: 0,//0=dia 1=mes 2=periodo

      date_m: '',

      hoje :'',
      hora :'',
      data_hora :'',
      today :'',
      yesterday :'',
      in_today: '',
      di: '', 
      df: '',
      di_c: '', //copia
      df_c: '',//copia 

      snack_timeout:2000,
      snackbar: false,
      snack_text: 'texto',
      snack_color: "success",

      notnullRules: [
            v => !!v || 'necessário'
      ],

      formas_pagamentos:[
        'DINHEIRO',
        'PIX',
        'BOLETO',
        'TRANSFERENCIA',
        'CARTAO DE CREDITO',
        'CARTAO DE DEBITO',
        'CHEQUE'
      ],

    }),

    watch: {

      janela_modal_abre(a){
        // quando fecho a janela pode chamar uma funcao
        if (!a){
          this.acao_after_modal(this.janela_modal_tabela)//passo o nome da tabela
        }
      },


      atrasadas_model(a){
        // this.salva_session('atrasadas_model')
      },

      previsao_model(a){
        // this.salva_session('previsao_model')
      },

      config_whats(a){
          if (a){
            this.status_zap()
          }
      },

      selected(val){

        // console.log('watch selected:');
        // console.log(this.selected.length);
        
        this.selecionou_varios = (this.selected?.length > 1)

        // quando selecione filtro valores
        if (this.selecionou_varios){
           this.somatoria_lista( 'selected' , 'double_TOTAL', 'TIPO')
        }

      },

      dialog_opcoes(a){
        if (a){
          setTimeout(function(){
            this.comp_focus = ''
          }.bind(this), 222);
        }
      },

      dialog_menu(a){
        if (a){
          setTimeout(function(){
            this.comp_focus = ''
          }.bind(this), 222);
        }
      },

      categoria_forma(a){

        if (a){

            setTimeout(function(){
              this.$set(this.filtros, 'categoria', 'TODOS')
              this.open_contas(this.di,this.df)//atualiza
            }.bind(this), 222);

        }
      },

      dialog_alterar_parcela(a){
        if (a){
          this.multiplicar = 1
        }
      },

      async search_conta(a){
        // quando procuro por algo, filtro valores
        // console.log('search_conta:', a);
        await this.getVisibleItems()
        await this.somatoria_lista( 'selectableItems' , 'double_TOTAL', 'TIPO')
      },

      items_somatorias(dados){
        // depois do filtro valores aqui pego o que eh entrada e o que eh saida

        const valoresE = dados.filter(item => item.CAMPO === "E").map(item => item.VALOR);
        this.sum_entrada = valoresE[0] 

        const valoresS = dados.filter(item => item.CAMPO === "S").map(item => item.VALOR);
        this.sum_saida = valoresS[0] 
      },

      di(di){
        sessionStorage['f:di'] = di
          // sessionStorage['f:df'] = df
      },

      df(df){
        // sessionStorage['f:di'] = di
          sessionStorage['f:df'] = df
      },


      tipo_dia(a){
        localStorage['F:tipo_dia'] = a
      },

      date_m(a){
        localStorage['F:date_m'] = a
      },

      'filtros.tipo_data'(a){
        localStorage['F:filtros.tipo_data'] = a
        this.permissoes_item()

        if (a=='[TODOS]'){
        
          this.tipo_dia = 1
          this.filtros.tipo = ''
          this.filtros.tipo_data = 'PREVISÃO'

          this.month_select()
        }
        else if ((a=='A RECEBER')||(a=='A PAGAR')){
        
          this.tipo_dia = 2
          this.df = this.today
        }
      },

      'edita_parcelas.PAGO'(a){
        // console.log(a);
         this.checkbox_efetuado = ( a == 'S') ? true : false
      },


    },

    beforeDestroy(){
      this.registerKeyHandler(false)
    },

    created(){
      this.onCreated()
    },


    methods:{

      async go_inverter_tipo(item){
        // console.log('go_inverter_tipo:', item);
        let tipo = item.TIPO

        if (tipo == 'E'){ tipo = 'S' }else { tipo = 'E' }

        let sql = `update conta set tipo = '${tipo}' where codigo = ${item.CODIGO}`

        await this.crud_sql(sql)
        
      },

      async Shift_V(event) {
        // console.log("here");

        if (event.shiftKey && event.key === "V") {
          // A combinação Shift + V foi pressionada
          event.preventDefault();

          if (this.obj_user.SUPER === "S") {
            try {
              // Simula um clique no primeiro registro do data-table como uma ação assíncrona
              const itemClicado = await this.simulaCliquePrimeiroRegistro();
              // console.log("item_clicou:", itemClicado);

              // Chama a função muda_visto após o clique
              // await this.muda_visto(itemClicado, false);

            } catch (error) {
              console.error("Erro ao processar a ação Shift + V:", error);
            }
          }
        }
      },

      async simulaCliquePrimeiroRegistro() {
        return new Promise((resolve, reject) => {
          // Encontra o primeiro registro no data-table
          const firstRow = document.querySelector(".v-data-table tbody tr");
          if (firstRow) {
            // Simula o clique no elemento
            
            firstRow.click();//quando clica ele ja clica na coluna visto, caso altere a ordem das colunas, ai chama o evento mudavisto()

            // Resolve com o item clicado (substitua pela lógica correta, se necessário)
            // Exemplo: use `this.items_conta[0]` como referência
            resolve(this.items_conta[0]);
          } else {
            reject(new Error("Nenhum registro encontrado no data-table."));
          }
        });
      },



     //pega todos os campos 
      async exportToExcel(item_name, nome) {
        // Cria uma nova planilha
        const ws = XLSX.utils.json_to_sheet(this[item_name]);
        // Define um novo arquivo de workbook (pasta de trabalho)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados");
        // Salva o arquivo como Excel
        XLSX.writeFile(wb,  nome+'.xlsx' || "tabela-dados.xlsx");
      },

      removeDuplicatesByCodigo(array) {
        const uniqueItems = [];
        const seenCodes = new Set();

        for (const item of array) {
          if (!seenCodes.has(item.CODIGO)) {
            seenCodes.add(item.CODIGO);
            uniqueItems.push(item);
          }
        }

        return uniqueItems;
      },

      
      salva_session(tipo){           
            sessionStorage[tipo]  =  JSON.stringify(this[tipo])
      },

      go_imprimir_recibo(qual){

        if (qual == 'RECIBO'){
            
            this.data_hora_agora()//ATUALIZA DATA HORA USER

            let obj_dados_recibo = {}

             
            this.$set(obj_dados_recibo, 'CODIGO'            , this.item_menu.CODIGO) 
            this.$set(obj_dados_recibo, 'double_TOTAL'      , this.item_menu.double_TOTAL) 
            // this.$set(obj_dados_recibo, 'VALOR'          , this.item_menu.x_double_TOTAL) 
            this.$set(obj_dados_recibo, 'VALOR'             , this.item_menu.double_TOTAL) 
            this.$set(obj_dados_recibo, 'FORMA'             , this.item_menu.FORMA_PAGAMENTO) 
            this.$set(obj_dados_recibo, 'DATA'              , this.item_menu.x_DATA_EFETUADO) 
            this.$set(obj_dados_recibo, 'TIPO'              , this.item_menu.TIPO) 
            this.$set(obj_dados_recibo, 'RECEBEU'           , this.quem_alterou)

            if (this.item_menu.X_NOME_ALUNO){
              this.$set(obj_dados_recibo, 'REFERENTE'         , this.item_menu.CATEGORIA + ' - '+ this.item_menu.SERVICO_REFERENTE ) 
              this.$set(obj_dados_recibo, 'NOME'              , this.item_menu.X_NOME_ALUNO) 
            }
            else if (this.item_menu.X_NOME_CLIENTE){
              this.$set(obj_dados_recibo, 'REFERENTE'         , ( (this.item_menu.CATEGORIA)||'') + ' - '+ this.item_menu.SERVICO_REFERENTE ) 
              this.$set(obj_dados_recibo, 'NOME'              , this.item_menu.X_NOME_CLIENTE)
            }else{
              this.$set(obj_dados_recibo, 'REFERENTE'         , this.item_menu.CATEGORIA) 
              this.$set(obj_dados_recibo, 'NOME'              , this.item_menu.SERVICO_REFERENTE)

            }
            
            
            
            


            // monto o objeto que sera lido la em relatorios.vue
            let obj_dados_impressao = {}

            this.$set(obj_dados_impressao, 'relatorios_show'    , true) //permite ver relatorio
            this.$set(obj_dados_impressao, 'qual'               , 'RECIBO')
            this.$set(obj_dados_impressao, 'obj_dados_recibo'   , obj_dados_recibo)


            // salvo na session para ser lido em relatorios.vue
            sessionStorage['obj_dados_impressao']  =  JSON.stringify(obj_dados_impressao)

            // abro a pagina relatorios.vue
            let route = this.$router.resolve({path: '/relatorios'});
            window.open(route.href, '_blank');

            // apos aberto, volto para false para nao ser lido fora do contexto
            this.$set(obj_dados_impressao, 'relatorios_show'    , false)

        }
      },

      remover_aluno(){
        if (!confirm(`Remover Aluno deste Lançamento?`)){
          return false 
        }else{
           this.$set(this.edita_parcelas, 'CODIGO_ALUNO', 0)
           this.$set(this.edita_parcelas, 'X_NOME_ALUNO', '')
           this.$set(this.edita_parcelas, 'CODIGO_SERVICO', 0)
        }
      },

      async acao_after_modal(acao){
        if (acao == 'ALUNOS'){
          
           let ccadastro
           
           try{
             ccadastro = JSON.parse(sessionStorage['cadastro_selecionados'])
             ccadastro = ccadastro[0]
           }catch(e){
             return false
           }


           if (!ccadastro){
            return false
           }

           console.log('ccadastro:', ccadastro);

           this.$set(this.edita_parcelas, 'CODIGO_ALUNO', ccadastro.CODIGO_ALUNO)
           this.$set(this.edita_parcelas, 'X_NOME_ALUNO', ccadastro.NOME)
           this.$set(this.edita_parcelas, 'CODIGO_SERVICO', ccadastro.CODIGO_SERVICO)
           
        }
      },

      async dialog_ok_clicou(tipo){

        if (tipo == 'conectar_zap'){
          //  somente verifico se conectado
            this.zapzap_conectado = await status_whats(false,false, 'connected')
        }

      },

      lista_categorias(quero){
        // this.categoria_forma = quero

        // caso clique 2 vez ele volta pra todos...
        if (this.filtros.categoria != quero){
            this.filtros.categoria = quero
        }
        else{
          this.filtros.categoria = 'TODOS'
        }
        this.open_contas(this.di,this.df)
      },

      async disparar_whats_parcelas(tipo){


        if (!confirm(`Enviar mensagens automaticamente para esta lista?`)){
          return false
        }


        let count_ok   = 0
        let meu_array

        if (tipo == 'ATRASADAS'){

            if (this.atrasadas_model.length === 0){
              // alert('vazio pega lista padao')
              meu_array = this.items_estat_atrasadas.map((x) => x); //clonando array copiar array
            }
            else{
              // alert('selecionou pega somente os selecionados')
              meu_array = this.atrasadas_model.map((x) => x); //clonando array copiar array
            }
        }
        
        else if (tipo == 'PREVISAO'){

            if (this.previsao_model.length === 0){
              // alert('vazio pega lista padao')
              meu_array = this.items_estat_previsao.map((x) => x); //clonando array copiar array
            }
            else{
              // alert('selecionou pega somente os selecionados')
              meu_array = this.previsao_model.map((x) => x); //clonando array copiar array
            }
        }





        let retorno   = ''

        this.zapzap_conectado = await status_whats(false, true, 'connected')
        // caso nao conectado..abre dialog ok
        if (!this.zapzap_conectado){

          if (this.zapzap_mostrar){
            this.dialog_ok      = true
            this.dialog_ok_tipo = 'conectar_zap'
          }
          else{
            alert('Módulo WhatsApp não Ativado!')
          }

          return false
        }



        this.overlay = true

        for (let i = 0; i < meu_array?.length; i++) {

            if (meu_array[i].CELULAR){
                            
              // this.$set(meu_array[i], 'CELULAR' , '44999685172')//somente para teste

              retorno = await this.before_enviar_whats(meu_array[i], tipo, true)
              // console.log('retorno:', retorno);

              if (retorno.id){
                count_ok++
                this.alerta(count_ok+ ` enviados` , "success");//so retorna ok ou error
              }
              
            }
            
        }


        if (tipo == 'ATRASADAS'){
          await this.estatisticas_atrasadas(2)
        }
        
        else if (tipo == 'PREVISAO'){
          await this.estatisticas_previsao(2)
        }


        
        
        this.overlay = false

        setTimeout(function(){
          if (count_ok>0){
             this.alerta(`${(count_ok)} mensagens enviadas em ${(count_ok)} segundos 😉` , "primary lighten-1", 5000);//so retorna ok ou error
          }
        }.bind(this), 999);

      },

      async ler_sessions_parametros(){
        this.filtros.tipo_data = (localStorage['F:filtros.tipo_data']) ? (localStorage['F:filtros.tipo_data']) : 'EFETUADO'       
        this.date_m            = (localStorage['F:date_m'])            ? (localStorage['F:date_m'])            : this.date_m
        this.tipo_dia          = (localStorage['F:tipo_dia'])          ? (localStorage['F:tipo_dia'])          : this.tipo_dia
        this.tipo_dia_string   = (localStorage['F:tipo_dia_string'])   ? (localStorage['F:tipo_dia_string'])   : this.tipo_dia
        
        // alert('inicio A:'+ this.tipo_dia_string)
        this.clicou_menu_datas(this.tipo_dia_string)
      },
      
      async grafico_categorias(item, quero, periodo, tipo){

        // alert(this.df)
        const mes = this.df.substring(5, 7); // Extrai o mês do df 
        

        copiarParaClipboard(item.TOTAL)

        let sql_where = `(conta.CATEGORIA LIKE '%${quero}%') AND`
        // let sql_where = `( (conta.CATEGORIA LIKE '%${quero}%') OR (conta.SERVICO_REFERENTE LIKE '%${quero}%') ) AND`
        // let sql_where = `conta.CATEGORIA IN (${queroPlaceholders}) AND`

        if (this.chart_comparar){

          if (!this.chart_quero.includes(`"${quero}"`)) {
              this.chart_quero.push(`"${quero}"`) 
              this.chart_title = this.chart_title + ' + ' +quero
          }

          sql_where = `(conta.CATEGORIA IN (${this.chart_quero})) OR (conta.SERVICO_REFERENTE IN (${this.chart_quero})) AND`
        }
        else{
          this.chart_quero = []//limpa array
          this.chart_quero.push(`"${quero}"`) 
          this.chart_title = quero
        }
        

        
        this.chart_color = (tipo == 'E') ? 'green' : 'red'
        // let sql = `SELECT
        //             DATE_FORMAT(data_vencimento, '%Y-%m') AS MES,
        //             COUNT(conta.codigo) AS QT,
        //             FLOOR(SUM(COALESCE(conta.double_TOTAL, conta.SERVICO_REFERENTE))) AS TOTAL,
        //             (SUM(COALESCE(conta.double_TOTAL, conta.SERVICO_REFERENTE))) AS double_TOTAL
        //           FROM conta
        //           WHERE 
        //              (data_vencimento BETWEEN DATE_SUB(CURDATE(), INTERVAL ${periodo} MONTH) AND LAST_DAY(CURDATE())) AND
        //              ${sql_where}
        //              (conta.TIPO = '${tipo}')
        //           GROUP BY
        //             DATE_FORMAT(data_vencimento, '%Y-%m')
        //           ORDER BY
        //             MES`

        let sql = `SELECT
                      DATE_FORMAT(data_vencimento, '%Y-%m') AS MES,
                      COUNT(conta.codigo) AS QT,
                      FLOOR(SUM(COALESCE(conta.double_TOTAL, conta.SERVICO_REFERENTE))) AS TOTAL,
                      SUM(COALESCE(conta.double_TOTAL, conta.SERVICO_REFERENTE)) AS double_TOTAL
                  FROM 
                      conta
                  WHERE 
                      (data_vencimento BETWEEN DATE_SUB(LAST_DAY(CONCAT(YEAR(CURDATE()), '-', '${mes}', '-01')), INTERVAL ${periodo} MONTH) 
                          AND LAST_DAY(CONCAT(YEAR(CURDATE()), '-', '${mes}', '-01')) ) AND
                      ${sql_where}
                      (conta.TIPO = '${tipo}')
                  GROUP BY 
                      DATE_FORMAT(data_vencimento, '%Y-%m')
                  ORDER BY 
                      MES;`

        // console.log('sql:', sql);   

        await this.crud_abrir_tabela(sql, 'items_chart') 

        let vem =  this.items_chart.map(item => parseInt(item.TOTAL));//pega somente os valores para o grafico


        // prepara o label para o grafico
        const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

        let labels =  this.items_chart.map(item => {
          const date = new Date(item.MES);
          const monthIndex = parseInt(item.MES.split('-')[1], 10) - 1;
          const monthName = monthNames[monthIndex]; // Obtém o nome do mês a partir do array
          const total = (item.x__double_TOTAL); // Obtém a parte inteira do total
          return `${monthName}:  ${total}`; // Retorna o formato "mês:total"
        });
        

        this.chart_values = vem
        this.chart_labels = labels

        // chart_values
        this.chart_dialog = true       

      },


      acha_juros(){
        
        // this.edita_parcelas.double_TOTAL = this.moeda(event)

        let total = this.strtofloat(this.edita_parcelas.double_TOTAL)
        let valor = this.strtofloat(this.edita_parcelas.double_VALOR)
        let juros = 0


        if ((total)&&(valor)){
            juros = parseFloat(total) - parseFloat(valor)

            if (juros>0){
              juros = this.floatTostring(juros)
              this.$set(this.edita_parcelas, 'double_JUROS', juros)
            }
        }

        // console.log('juros:', juros);
      
      },

      async before_enviar_whats(item, tipo, auto){


        let empresa = sessionStorage['lg:EMPRESA']
        let quebra  = (auto) ? '\n' : '%0A'
        let quebra2 = (auto) ? '\n\n' : '%0A%0A'
        let msg  
        let retorno
        let sql
 
        // if (tipo == 'ATRASADAS'){
        if (['ATRASADAS', 'PREVISAO'].includes(tipo)){

           let nome = await primeiro_nome(item.NOME)
 
          if (tipo == 'ATRASADAS'){


            if (this.obj_dados_empresa.MSG_COBRANCA?.length>10){
             
              let nome = await primeiro_nome(item.NOME)
              nome = await DSibasico.primeira_maiuscula(nome)

              msg = this.obj_dados_empresa.MSG_COBRANCA
              msg = msg.replaceAll('@ide'      , item.CODIGO_ALUNO) 
              msg = msg.replaceAll('@vence'    , item.x_DATA_VENCIMENTO )  
              msg = msg.replaceAll('@valor'    , 'R$ '+ this.getFormatCurrency(item.TOTAL,'N') ) 
              msg = msg.replaceAll('@aluno'    , nome ) 
              msg = msg.replaceAll('@empresa'  , this.obj_dados_empresa.NOME ) 

            }
            else{

              // mensagem padrao 🛎️
              msg = `📌 *Cobrança Automática* `+quebra2+
                    `${item.CODIGO_ALUNO} ${quebra}Olá *${nome}* ${quebra}Sua parcela com Vencimento em *${item.x_DATA_VENCIMENTO}* `+
                    `no Valor de *R$ ${item.TOTAL}* está *pendente* até o momento.${quebra2}`+
                    ` * Caso o pagamento já tenha sido efetuado, favor desconsiderar esta mensagem.${quebra2}`+
                    `*${empresa}*`
            }

          }
          else if (tipo == 'PREVISAO'){


            if (this.obj_dados_empresa.MSG_PREVISAO?.length>10){
             
              let nome = await primeiro_nome(item.NOME)
              nome = await DSibasico.primeira_maiuscula(nome)

              msg = this.obj_dados_empresa.MSG_PREVISAO
              msg = msg.replaceAll('@ide'      , item.CODIGO_ALUNO) 
              msg = msg.replaceAll('@vence'    , item.x_DATA_VENCIMENTO )  
              msg = msg.replaceAll('@valor'    , 'R$ '+ this.getFormatCurrency(item.TOTAL,'N') ) 
              msg = msg.replaceAll('@aluno'    , nome ) 
              msg = msg.replaceAll('@empresa'  , this.obj_dados_empresa.NOME ) 

           }
           else{
              msg = `🛎️ *Aviso Automático de Vencimento*` + quebra2 +  
                    `${item.CODIGO_ALUNO} ${quebra}Olá *${nome}* ${quebra}Para sua comodidade, informamos que sua parcela de *R$ ${item.TOTAL}* vence em *${item.x_DATA_VENCIMENTO}* `+ quebra2 +
                    
                    ` * Caso o pagamento já tenha sido efetuado, favor desconsiderar esta mensagem.${quebra2}`+
                    `*${empresa}*`
            }
          }


          //envia automatico pelo modulo whatsapp  
          if (auto){
             retorno = await send_whats_api(tipo, item.CELULAR, msg, false);
          }
           else{    
             retorno = this.enviar_whats_manual(item, msg)              
          }

          await registra_log('WHATSAPP', negritoO(`ENVIOU WHATS [${(auto) ? 'auto':'manual'}] `,'green') + negritoO(tipo, (tipo=='ATRASADAS')?'red': 'green')  + ` para ` + negritoO(item.NOME,false) , item.CODIGO_ALUNO)
 
          // console.log('before_enviar_whats - retorno:', retorno);

          // seta na tabela que foi enviado whatsapp
          if (retorno.id){
              let tipooo = (tipo == 'ATRASADAS') ? 'WHATS_ATRASADO' : (tipo == 'PREVISAO') ? 'WHATS_LEMBRETE' : ''
              sql = `update conta set ${tipooo} = 'S' where CODIGO = ${item.CODIGO}`
              // console.log('sql:', sql);
              await this.crud_sql(sql)
          }
          
            
          return retorno
        }
      },

      enviar_whats_manual(item, msg){
        send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO, msg)
      },


      filtra_search(valor){

        if (!valor){
          return false
        }

        this.search_conta = valor.toLowerCase()

        setTimeout(function(){
           this.getVisibleItems()            
           this.somatoria_lista( 'selectableItems' , 'double_TOTAL', 'TIPO')
        }.bind(this),  111); 
        
      },

      firstUpper(event, field){
            // console.log(event);
            let a = event.target.value;//captura o valor do event

            if (a!=='' && a.length==1){  
            a = a.toUpperCase();
            // this.item_edita[field] = a;

            event.target.value = a
            } 
      },

      abrir_cadastro(ide, nova_aba){
        sessionStorage['cadastro_abrir_ide'] = ide
        
        if (nova_aba){
          let route = this.$router.resolve({path: '/cadastro'});
            window.open(route.href, '_blank');
        }
        else{
            this.$router.push('/cadastro')//redireciono para a pagina do router
        }
      },

      abre_janela_modal(status, edicao, tabela, titulo){
        
        this.janela_modal_tabela   = tabela
        this.janela_modal_titulo   = titulo
        this.janela_modo_edicao    = edicao
        this.janela_modal_abre     = status

        // this.janela_modal_tabela = tabela
        // this.janela_modal_titulo = titulo
        // this.janela_modo_edicao  = edicao
        // this.janela_modal        = status
      },

      janela_modal_change(valor){
        this.janela_modal_abre = valor

        if (!valor){
          // alert('atualiza')
          this.minhas_tarefas()
        }
      },

      async getVisibleItems() {
      // Use this.$refs para acessar o componente v-data-table
        const dataTable = this.$refs.data_tabble_1;
        // console.log(dataTable);

        // Acesse os itens visíveis através do método itemsPerPage para versões mais recentes do Vuetify
        this.selectableItems = dataTable.selectableItems;
      },

      enter_filtar(){
        // usar isso quando dar enter.. ai filtra os itens na tela..porem quando executo o search ele pega tudo e nao somente o que esta na tela.. entao nao estou usando
          // this.somatoria_lista( 'items_conta' , 'double_TOTAL', 'TIPO')        
      },

      async somatoria_lista(item_name, campo_valor,  campo_parametro ){

        // percorro o array de objeto somando os valores pelo nome do campo..
        const resultado = {};
        this.items_somatorias = []; // Limpa o array de somatórias

        if (campo_parametro) {
            let total = 0;

            this[item_name].forEach((item) => {
                const campo = item[campo_parametro] || campo_parametro; // Se o campo não existir, usa o valor de campo_parametro
                const valor = parseFloat(this.strtofloat(item[campo_valor]));


                if (resultado[campo]) {
                    resultado[campo] += parseFloat(valor);
                } else {
                    resultado[campo] = parseFloat(valor);
                }

                total += valor;
            });

            resultado['TOTAL'] = parseFloat(total);

            // Criar um novo array de objetos com os valores agrupados e a quantidade por campo
            const novoArrayDeObjetos = Object.keys(resultado).map((campo) => {
                return {
                    CAMPO: campo,
                    VALOR: resultado[campo].toFixed(2),
                    'QT': campo !== 'TOTAL' ? this[item_name].filter(item => item[campo_parametro] === campo).length : this[item_name].length
                    // 'QT': campo !== 'TOTAL' ? this[item_name].filter(item => item[campo_parametro] === campo).length : ''
                };
            });

            // console.log(novoArrayDeObjetos);
            this.items_somatorias = novoArrayDeObjetos;

        }   
      },


      efetuar_parcial(){
        // console.log('parcial:', this.item_menu);
        
        this.dados_parcial = {}//limpo objeto


        this.$set(this.dados_parcial, 'CODIGO'           , this.item_menu.CODIGO)
        this.$set(this.dados_parcial, 'double_TOTAL'     , this.item_menu.double_TOTAL)


        // adiciono novo lançamento
        this.adicionar('S')


        // copio os dados originais para a edição
        this.edita_parcelas = Object.assign({}, this.item_menu);//faço uma copia do original


        // limpo alguns campos
        this.$set(this.edita_parcelas, 'CODIGO'           , null)
        this.$set(this.edita_parcelas, 'DATA_VENDA'       , this.today)


        this.$set(this.edita_parcelas, 'double_VALOR'     ,'' )
        this.$set(this.edita_parcelas, 'double_JUROS'     ,'' )
        this.$set(this.edita_parcelas, 'double_TAXA'      ,'' )
        this.$set(this.edita_parcelas, 'double_TOTAL'     ,'' )
        this.$set(this.edita_parcelas, 'PLANO'            ,'p' )


      },

      async calcula_parcial(){

        alert('Lançando parcial')

        let valorOriginal = this.strtofloat(this.dados_parcial.double_TOTAL)
        let valorParcial  = this.strtofloat(this.item_menu_copia.double_TOTAL)
        
        let valorAtualizado = parseFloat(valorOriginal) - parseFloat(valorParcial)

        let codigo =  this.dados_parcial.CODIGO
        // limpo o objeto
        this.dados_parcial = null //limpa o objeto

        if (valorAtualizado<=0){
          alert('Valor Parcial maior do que Valor Original! Sendo assim não será alterado o valor da parcela original.')

          return false
        }

        // executa o update do valor original
        let sql = `update conta set double_VALOR = '${valorAtualizado}', double_TOTAL = '${valorAtualizado}' where codigo = ${codigo}`
        await this.crud_sql(sql)

        await this.tabela('conta','update')//update da tabela

      },

      rowClass(item){
        // console.log(item);
        let r = this.getColorLinha(item)

        // console.log(r);
        return r
        // return 'border-bottom';
      },

      getColorLinha (item) {
        
        let r = ''

        let v = item.VISTO
               
        if (v == 'S') r =  'grey lighten-4'
        else r = ''

        return r


      },

      avanca_data(acao, tipo, data ){

        let mais = 1
        if (acao == 'v'){ mais = -1}// (v)olta ou (a)vanca

        let s = ''
        // console.log('avanca_data:', data);

        if (tipo == 'm'){
          s = data +'-01' //transformo numa data valida
          s = this.incMonth(s, mais,'us')//mudo o mes
          s = s.slice(0, 7)//copio formato ano-mes
          this.date_m = s
          this.month_select()//atualizo a lista
        }
        else if (tipo == 'd'){
          s = this.incDay(data, mais)
          this.in_today = s
          this.day_click()//atualiza
        }
      },

      async minhas_tarefas(){

        // return false

        let codigo = this.obj_user.CODIGO
        let where = `where (visto <>'S')  and (CODIGO_OPERADOR  = ${codigo})` 
        let sql = 
        `select * FROM tarefas ${where} ORDER by DATA`
        // `select * FROM tarefas ORDER by DATA`
        // console.log('sql:', sql);
        await this.crud_abrir_tabela(sql, 'items_tarefas')
      },

      insere_item(item){

        setTimeout(function(){            
            this.items_data.unshift(item)
        }.bind(this),  111); 
      },

      async comAtraso(){

        setTimeout(function(){            
        
           this.saldo_asaas()

          //  this.status_zap()//deixei no watch

        }.bind(this),  999); //deixar em 6000 pq ele esta sendo lido la em app.vue..somente a 1vez
      },


      async estatisticas_counts(){
        let sql = `

          SELECT
            SUM(CASE WHEN conta.DATA_VENCIMENTO BETWEEN DATE_FORMAT(CURDATE() - INTERVAL 2 MONTH, '%Y-%m-%d') AND CURDATE() - INTERVAL 1 DAY THEN 1 ELSE 0 END) AS COUNT_ATRASADAS,
            SUM(CASE WHEN conta.DATA_VENCIMENTO BETWEEN CURDATE() AND CURDATE() + INTERVAL 3 DAY THEN 1 ELSE 0 END) AS COUNT_PREVISAO
          FROM
            conta
            LEFT OUTER JOIN alunos ON conta.CODIGO_ALUNO = alunos.CODIGO_ALUNO
          WHERE
            conta.pago = 'N'
            AND conta.tipo <> 'S'
            AND (
              alunos.CODIGO_ALUNO IS NULL OR alunos.STATUS <> 'CANCELADO'
            )
            AND (
              conta.DATA_VENCIMENTO < CURDATE() -- Para incluir todas as atrasadas
              OR
              conta.DATA_VENCIMENTO <= CURDATE() + INTERVAL 3 DAY -- Para incluir até as que vão vencer em 3 dias
            );

        `

        // console.log('sql:', sql);

        let rr 
        if (rr =  await this.crud_abrir_tabela(sql, 'items_estat_counts')){
          // console.log('retornou:', rr);
          this.count_atrasadas = rr[0].COUNT_ATRASADAS
          this.count_previsao  = rr[0].COUNT_PREVISAO
        }
      },

      async estatisticas_previsao(meses){

        // so abre 1 vez
        if (this.items_estat_previsao.length !== 0){
          // return false
        }

        let sql = 
        `
        SELECT
          conta.CODIGO,
          conta.CODIGO_ALUNO,
          conta.CODIGO_CLIENTE,
          conta.DATA_VENCIMENTO,
          conta.WHATS_LEMBRETE,
          conta.SERVICO_REFERENTE,
          conta.double_TOTAL as TOTAL,
          CASE
            WHEN conta.CODIGO_ALUNO > 0 THEN alunos.NOME
            WHEN conta.CODIGO_CLIENTE > 0 THEN clientes.NOME
            ELSE conta.CATEGORIA
          END AS NOME,

          CASE
            WHEN conta.CODIGO_ALUNO > 0 THEN alunos.CELULAR
            WHEN conta.CODIGO_CLIENTE > 0 THEN clientes.CELULAR
            ELSE 'NAO DEFINIDO'
          END AS CELULAR
        FROM
          conta
          LEFT OUTER JOIN clientes ON conta.CODIGO_CLIENTE = clientes.CODIGO
          LEFT OUTER JOIN alunos ON conta.CODIGO_ALUNO = alunos.CODIGO_ALUNO
        WHERE
          (conta.pago = 'N') AND
          (conta.tipo <> 'S') AND
          (conta.DATA_VENCIMENTO BETWEEN CURDATE()  AND CURDATE() + INTERVAL 3 DAY )
          AND (
            alunos.CODIGO_ALUNO IS NULL OR alunos.STATUS <> 'CANCELADO'
          )
        ORDER BY
          DATA_VENCIMENTO, NOME

        `
        console.log('sql:', sql);

        await this.crud_abrir_tabela(sql, 'items_estat_previsao')

      },

      async estatisticas_atrasadas(meses){

        // so abre 1 vez
        if (this.items_estat_atrasadas.length !== 0){
          // return false
        }

        let sql = 
        `
        SELECT
          conta.CODIGO,
          conta.CODIGO_ALUNO,
          conta.CODIGO_CLIENTE,
          conta.DATA_VENCIMENTO,
          conta.WHATS_ATRASADO,
          conta.SERVICO_REFERENTE,
          conta.double_TOTAL as TOTAL,
          CASE
            WHEN conta.CODIGO_ALUNO > 0 THEN alunos.NOME
            WHEN conta.CODIGO_CLIENTE > 0 THEN clientes.NOME
            ELSE conta.CATEGORIA
          END AS NOME,

          CASE
            WHEN conta.CODIGO_ALUNO > 0 THEN alunos.CELULAR
            WHEN conta.CODIGO_CLIENTE > 0 THEN clientes.CELULAR
            ELSE 'NAO DEFINIDO'
          END AS CELULAR
        FROM
          conta
          LEFT OUTER JOIN clientes ON conta.CODIGO_CLIENTE = clientes.CODIGO
          LEFT OUTER JOIN alunos ON conta.CODIGO_ALUNO = alunos.CODIGO_ALUNO
        WHERE
          (conta.pago = 'N') AND
          (conta.tipo <> 'S') AND
          (conta.DATA_VENCIMENTO BETWEEN DATE_FORMAT(CURDATE() - INTERVAL ${meses} MONTH, '%Y-%m-%d') AND CURDATE() - INTERVAL 1 DAY )
          AND (
            alunos.CODIGO_ALUNO IS NULL OR alunos.STATUS <> 'CANCELADO'
          )
        ORDER BY
          DATA_VENCIMENTO, NOME

        `
        // console.log('sql:', sql);

        await this.crud_abrir_tabela(sql, 'items_estat_atrasadas')

      },

      async estatisticas_categoria(){

        let where = this.sqlWhere_2

        let sql = 
        `

        SELECT DISTINCT
          COUNT(conta.codigo) as QT,
          SUM(COALESCE(conta.double_TOTAL, conta.SERVICO_REFERENTE)) AS TOTAL,
          CONCAT(
            COALESCE(NULLIF(conta.CATEGORIA, ''), LEFT(conta.SERVICO_REFERENTE, 19)),
            IF(CHAR_LENGTH(conta.SERVICO_REFERENTE) > 20, '', '')
          ) AS NOME,
          conta.TIPO
        FROM conta
        ${where}
        GROUP BY
          COALESCE(NULLIF(conta.CATEGORIA, ''), conta.SERVICO_REFERENTE),
          conta.TIPO
        ORDER BY
          TIPO desc, nome, TOTAL DESC
        `
        console.log('estatisticas_categoria:', sql);
        await this.crud_abrir_tabela(sql, 'items_estat_categoria')

        if (!this.est_grupo_agrupado){
           await this.separa_categorias(this.items_estat_categoria)
        }

      },

      async separa_categorias(items){


        const novoArray = items.map(item => {
        const [CATEGORIA, OPERACAO] = item.NOME.split(' - ');

          return {
            ...item,
            CATEGORIA,
            OPERACAO
          };
        });

        // console.log('novoArray:', novoArray);
        // this.items_estat_categoria = novoArray



      // Agrupa os itens pelo campo "CATEGORIA" e "TIPO" e calcula a soma dos campos "QT" e "TOTAL" para cada grupo
      const novoArray2 = Object.values(novoArray.reduce((acc, item) => {
          const categoria = item.CATEGORIA.toUpperCase();
          const tipo = item.TIPO.toUpperCase(); // Considera que o campo TIPO já está em maiúsculas
          const chave = `${categoria}-${tipo}`; // Cria uma chave única para categoria e tipo
          acc[chave] = acc[chave] || { ...item, TOTAL: 0, QT: 0, CATEGORIA: categoria, TIPO: tipo };
          acc[chave].TOTAL += parseFloat(item.TOTAL);
          acc[chave].QT += parseInt(item.QT, 10);
          return acc;
        }, {}));

      // Agrupa os itens pelo campo "CATEGORIA" e calcula a soma dos campos "QT" e "TOTAL" para cada grupo
      // const novoArray2 = Object.values(novoArray.reduce((acc, item) => {
      //   const categoria = item.CATEGORIA.toUpperCase();
      //   acc[categoria] = acc[categoria] || { ...item, TOTAL: 0, QT: 0 };
      //   acc[categoria].TOTAL += parseFloat(item.TOTAL);
      //   acc[categoria].QT += parseInt(item.QT, 10);
      //   return acc;
      // }, {}));

      // console.log(novoArray2);
      this.items_estat_categoria = novoArray2


      },


      async estatisticas_formas(){

        
        let where = `WHERE (DATA_VENCIMENTO BETWEEN '${this.di}' and '${this.df}')`
        where = this.sqlWhere_2

        let sql = 
        `
        select 
          COUNT(conta.codigo) as QT,
          SUM(conta.double_TOTAL) as TOTAL,
          conta.FORMA_PAGAMENTO,
          conta.TIPO
        from conta 
        ${where}
        GROUP by 
          conta.FORMA_PAGAMENTO,
          conta.TIPO
        order by TIPO, FORMA_PAGAMENTO, TOTAL
        `
        // console.log('estatisticas_formas:', sql);
        await this.crud_abrir_tabela(sql, 'items_estat_formas')

        // nao precisa pois eh o memos valor que esta la em saldo na rodape
        if (await total_da_lista(this, 'items_estat_formas', 'items_somatorias', 'TOTAL', 'TIPO', false) ){
           //removo o campo TOTAL pq esta somando nao precisa 
          this.items_somatorias = this.items_somatorias.filter(item => item.CAMPO !== "TOTAL");
        }



      },
      
      isDataTableFocused(id) {
        // const componente = this.$refs.data_table_1.$el;
        // console.log('componente:', componente);

        // const comp = document.getElementById(id)
        const componente = document.getElementById("data_tabble_1")
        console.log('comp:', componente);

        // Verifique se o elemento DOM está focado
        const isFocused = document.activeElement === componente;

        console.log('isFocused:', isFocused);
        return isFocused;
      },

      // ATIVA O MODO DE DETECCAO DAS TECLAS DO DATATABLE
      registerKeyHandler(register) {

          if (register) {
              window.addEventListener('keydown', this.keyHandler)
              // window.addEventListener('keypress', this.keyHandler)
              // window.addEventListener('keyup', this.keyHandler)
          } else {
              window.removeEventListener('keydown', this.keyHandler)
              // window.removeEventListener('keyup', this.keyHandler)
              // window.removeEventListener('keypress', this.keyHandler)
          }
      },

      // DETECTA TECLA PRESSIONADA NO DATATABLE
      keyHandler(event) {
        // console.log('event:', event);
          if (this.comp_focus =='data_table') {

            if (event.code === 'KeyS'){
              this.selected.push(this.item_clicou) //preenchendo array com o objeto clonado
            }
           
            else if (event.code === 'KeyV'){
              if(this.obj_user.SUPER=='S'){
                this.muda_visto(this.item_clicou, false)
              }
            }

          }
      },

      // ORDENA OS ITENS DO DATATABLE
      customSort(items, index, isDesc) {

        items.sort((a, b) => {
          let fieldA = a[index[0]];
          let fieldB = b[index[0]];
          let fieldName = index[0];

          if (!fieldName){
            return 'exit'
          }

          // console.log('fieldName:', fieldName);
          // console.log('fieldA:', fieldA);

          if (fieldName.includes('double_')){
          // if (fieldName == 'double_VALOR'){
            fieldA = this.strtofloat(fieldA)
            fieldB = this.strtofloat(fieldB)
          }


          if (index[0] === "date") {
            if (!isDesc[0]) {
              return a.time_stamp - b.time_stamp;
            } else {
              return b.time_stamp - a.time_stamp;
            }
          } else if (!isNaN(fieldA) && !isNaN(fieldB)) {
            // Se ambos os campos são números, faça a comparação como números
            if (!isDesc[0]) {
              return fieldA - fieldB;
            } else {
              return fieldB - fieldA;
            }
          } else if (!isNaN(fieldA)) {
            // Se apenas o campo A é um número, coloque-o antes
            return -1;
          } else if (!isNaN(fieldB)) {
            // Se apenas o campo B é um número, coloque-o antes
            return 1;
          } else {
            // Ambos os campos não são números, faça a comparação de strings
            if (!isDesc[0]) {
              return fieldA < fieldB ? -1 : 1;
            } else {
              return fieldB < fieldA ? -1 : 1;
            }
          }
        });

        return items;
      },

      hideMenu () {
        const element = document.querySelector(".v-menu__content--fixed"); 
        // console.log('element:', element)
        element.style.display = "none";//nao deu..entao eu vejo o status e executo algo
        this.focus_by_id('id_categoria')
      },

      async insere_todos(componente, campo){

          let obj = {}

          //preecho o objeto
          this.$set(obj, 'CODIGO', 0)
          this.$set(obj,  campo, 'TODOS')

          //adiciono no array posicao inicial
          try{ 
            this[componente].unshift(obj)
          }catch(e){}
      },

      insere_texto_lista(item_name, texto){
        this[item_name].push(texto.toUpperCase())
      },

      async focus_by_id(id){
         document.getElementById(id).focus();
      },

      ocultar_rodape(){
         this.mostrar_rodape =! this.mostrar_rodape
         localStorage['F:mostrar_rodape'] = this.mostrar_rodape
      },

      async transferir_saldo(){
        console.log('this.send_conta:', this.send_conta);
        if (!this.send_conta.CHAVE_PIX){
           alert('Defina a Chave PIX desta Conta!\nClique em Tabelas, Contas' )
           return 'exit'
        } 

        if (!this.send_conta.TIPO_CHAVE){
           alert('Defina o Tipo de Chave PIX desta Conta!\nClique em Tabelas, Contas')
           return 'exit'
        } 

        let valor = this.send_valor
        console.log('send_valor:', this.send_valor);

        valor = valor.replaceAll('.', '') //removo tabulacao
        valor = valor.replaceAll(',', '.') //removo tabulacao

        console.log('valor:', valor);

        let tipo = this.send_conta.TIPO_CHAVE
        if (tipo == 'CELULAR'){
           tipo = 'PHONE'
        }

        let chave = this.send_conta.CHAVE_PIX
        let desc  = this.send_conta.DESCRICAO



        if ((this.config_emp.PIX_SITUACAO == 'TESTE')||(this.config_emp.PIX_SITUACAO == 'PRODUÇÃO')){
           if (this.config_pix.PIX_LINK){
             
    
              let token  = this.config_pix.PIX_TOKEN

              var url =  sessionStorage['url']
              let link = url+"clientes/saldo.php" //tem que ter esse url para nao dar erro de cors

              const data = {
                    acao               : 'transferir',
                    token              : token,     
                    value              : valor,     
                    pixAddressKey      : chave,     
                    pixAddressKeyType  : tipo,     
                    description        : desc,     
              }  

              const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber
              const res = await axios.post(link, data, header);//tem que ter 3 parametros

              console.log(res.data);
              
              if (res.data){
                 if (res.data.id){
                    if (res.data.authorized == false){
                      alert('Abra o Aplicativo '+ this.plataforma_cobranca +' para validar a transação!')
                    }
                    else{
                      alert('Transferência realizada com sucesso!')
                    }
                 }
              }
              

              if (res.data.errors){
                if (res.data.errors[0].description){
                  alert(res.data.errors[0].description)
                }
              }

              await this.saldo_asaas()

           }
        }
        


      },

      async status_zap(acao){

        // return 'exit'
        // console.log('status_zap:', this.config_whats);
        let url = ''
        if ((this.config_whats.SITUACAO == 'TESTE')||(this.config_whats.SITUACAO == 'ATIVO') ||(this.config_whats.SITUACAO == 'PROMOVER')){
          
           if (this.config_whats.API_LINK){
                         

              let retorno = await  status_whats()
              // console.log('RERERE:', retorno);
              
              this.zap_message = retorno.error
              this.zap_status  = retorno.connected

           }

           if (acao == 'qrcode'){

              // somente super A pode ler codigo.. caso de secretaria virtual..depois tem que configurar isso
              // if (!this.superA){
              //   alert('Entre em contato com a DSi sobre o status do whatsapp!')
              //   return 'exit'
              // }
              let ide      = sessionStorage['lg:IDE']
              let token    = parseInt(ide * 777888999)

              url  = this.config_whats.API_LINK
              url = url?.replaceAll('/send-messages','') //removo caracter especial
              url = url?.replaceAll('@token', token) //removo caracter especial


              // uso esse link para ler http dentro de https
              let url_https = `https://dsi.app.br/f/c/clientes/getQRCode.php?url=`+ url
              

              window.open(url, '_blank');
              return false


              this.url_externo = url //vai abrir no iframe == erro de https dentro de http
              this.dialog_qrcode = true

           }
        }
        
      },

      async saldo_asaas(){

        // return 'exit'
        // console.log('saldo_asaas:', this.config_emp);

        let token

        if (this.config_emp.PIX_SITUACAO == 'TESTE'){
            
            if (this.config_pix.PIX_TOKEN_TESTE){
               token  = this.config_pix.PIX_TOKEN_TESTE
            }
        }
        else if (this.config_emp.PIX_SITUACAO == 'PRODUÇÃO'){
            
            if (this.config_pix.PIX_LINK){
              token  = this.config_pix.PIX_TOKEN
            }

        }
        else{
          return false
        }



        var url =  sessionStorage['url']
        let link = url+"clientes/saldo.php" //tem que ter esse url para nao dar erro de cors

        const data = {
              acao     : 'saldo',
              token    : token,     
        }  

        const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber
        const res = await axios.post(link, data, header);//tem que ter 3 parametros

        // console.log('saldo_asaas:',  res.data);
        if (res.data.balance){
           this.cobranca_sum = res.data.balance
        }
        this.plataforma_cobranca = this.config_pix.PIX_PLATAFORMA
        
      },

      
      ocultar_quando(condicao){
        // mostra somente a barra lateral direita

        // return true

        if (condicao == 'basico'){
          
            if (this.obj_user.FINANCEIRO_R=='B'){
               return false
            }
            else{
              return true
            }
        }
        else{
          return true
        }        
      },


      pode_alterar(condicao){
            
        if ( (this.obj_user.SUPER=='S') || (this.obj_user.FINANCEIRO_U=='S')){
            return true
        }
        else{

            //acesso limitado pode permitir algumas coisas, pode adicionar mas nao pode alterar
            if (condicao == 'limitado'){
               if (this.obj_user.FINANCEIRO_R=='L'){
                return true
               }
            }
            
            // mostra somente a barra lateral direita
            else if (condicao == 'basico'){
               if (this.obj_user.FINANCEIRO_R=='B'){
                return true
               }
            }
            
            else if (condicao == 'dre'){
               if (this.obj_user.DRE=='S'){
                return true
               }
            }

            return false
        }

        return false
            
      },


      vif_here(acao){

        if (acao == 'acao_gerar_cobranca'){
           return  (this.souDSi)&&(this.item_menu.PAGO=='N')&&(!this.item_menu.PIX_ID) &&(this.item_menu.TIPO=='E')
        }

        else if (acao == 'imprimir_recibo'){
          //  return  ( (this.item_menu.TIPO == 'E') && (this.item_menu.PAGO == 'S') )
           return  (this.item_menu.PAGO == 'S') 
        }

        else if (acao == 'acao_abrir_cadastro'){
           return  (this.item_menu.CODIGO_ALUNO > 0)
        }

        else if (acao == 'acao_ver_cobranca'){
           return  (this.souDSi)&&(this.item_menu.PIX_URL)
        }

        else if (acao == 'acao_calc_juros'){
          return  (this.souDSi)&&(this.item_menu.PAGO=='N')&&(0>1)
        }

        else if ((acao == 'acao_efetuar_parcial')){
           return  (this.item_menu.PAGO=='N')&&(this.item_menu.TIPO=='S')
        }

        else if ((acao == 'all_efetuar')){
           return  (this.item_menu.PAGO=='N')
        }

        else if ((acao == 'acao_cobranca_zap_m') || (acao == 'acao_cobranca_zap_a')){
           return  (this.souDSi)&&(this.item_menu.PAGO=='N')&&(this.item_menu.TIPO=='E')
        }

        else if (acao == 'acao_cobranca_mail'){
           return  (this.souDSi)&&(this.item_menu.TIPO=='E')
        }

        else if ((acao == 'acao_del_cobranca')||(acao == 'acao_editar')||(acao == 'acao_editar')||(acao == 'all_del_cobranca')){
          
           return this.pode_alterar()
        }

        else if (acao == 'ÍTENS SELECIONADOS'){
          return this.selecionou_varios
          return true//somente para testar
        }

        else if (acao == 'inverter_tipo'){
          return (this.obj_user.SUPER=='S')
        }

        else if (acao == 'all_gerar_cobranca'){
          return ((this.souDSi)&&(this.item_menu.PAGO=='N'))
        }

        else if (acao == 'all_cobranca_zap_a'){
          return ((this.souDSi)&&(this.item_menu.PAGO=='N'))
        }

        else if (acao == 'all_cobranca_mail'){
          return (this.souDSi)
        }

        else if ((acao == 'all_efetuar') || (acao == 'all_estornar')){
          return (this.souDSi)
        }

        else{
          return true
        }

      },

      clicou_menu_parcelas(acao){
        // alert(acao)
        this.acao_atual = acao



        if (acao == 'acao_editar'){
          this.editar_tabela(this.item_menu, 'edita_parcelas', 'dialog_alterar_parcela')
        }

        else if (acao == 'imprimir_recibo'){
           this.go_imprimir_recibo('RECIBO')
        }

        else if (acao == 'acao_abrir_cadastro'){
           this.abrir_cadastro(this.item_menu.CODIGO_ALUNO)
        }

        else if (acao == 'acao_efetuar_parcial'){
           this.efetuar_parcial()
        }

        else if (acao == 'all_del_cobranca'){
           this.excluir_items_selecionados()
        }

        else if (acao == 'all_estornar'){
           this.estornar_items_selecionados()
        }

        else if (acao == 'all_efetuar'){
           this.efetuar_items_selecionados()
        }

        else if (acao == 'all_cobranca_mail'){
           this.prepara_email(this.selected, 'selecionados')
        }

        else if (acao == 'all_cobranca_zap_a'){
           this.executar_acao(this.selected, 'whats_cobranca', 'Enviando..')
        }

        else if (acao == 'inverter_tipo'){

          if (confirm(`Deseja Inverter o tipo de lançamento? Entrada => Saida e vice versa`)){
             this.executar_acao(this.selected, 'inverter_tipo', 'Alterando..')
          }
        }

        else if (acao == 'all_gerar_cobranca'){
           this.executar_acao(this.selected, 'pix', 'Gerando..')
        }

        else if (acao == 'acao_del_cobranca'){
           this.remover_tabela('conta',this.item_menu, '')
        }

        else if (acao == 'acao_cobranca_zap_a'){
           this.executar_acao(this.item_menu, 'whats_cobranca', 'Enviando..')
        }

        else if (acao == 'acao_cobranca_zap_m'){
           this.enviar_whats(this.item_menu,'clientes','pix')
        }

        else if (acao == 'acao_calc_juros'){
           this.atualiza_juros(this.item_menu, true, true)
        }

        else if (acao == 'acao_gerar_cobranca'){
           this.executar_acao(this.item_menu, 'pix', 'Gerando')
        }

        else if (acao == 'acao_ver_cobranca'){
           window.open(this.item_menu.PIX_URL, '_blank');
        }

        else if (acao == 'acao_cobranca_mail'){
           this.prepara_email(this.item_menu, 'este')
        }

        this.dialog_menu = false
      },


      clicou_menu_tipo_data(acao){

        this.acao_atual = acao

        if (acao == 'efetuado_hoje'){
          this.filtros.tipo_data = 'EFETUADO'
          this.in_today = this.today
          this.day_click()
        }
        else if (acao == 'efetuado_mes'){
          this.filtros.tipo_data = 'EFETUADO'
          this.month_select()
        }
        
        else if ((acao == 'previsao_pagar_hoje')) {

          this.insere_item('PREVISÃO_PAGAR')

          this.filtros.tipo_data = 'PREVISÃO_PAGAR'
          this.in_today = this.today
          this.day_click()
        }
        
        else if ((acao == 'previsao_receber_hoje')) {

          this.insere_item('PREVISÃO_RECEBER')
          
          this.filtros.tipo_data = 'PREVISÃO_RECEBER'
          this.in_today = this.today
          this.day_click()
        }
        
        else if ((acao == 'previsao_hoje')) {
          this.filtros.tipo_data = 'PREVISÃO'
          this.in_today = this.today
          this.day_click()
        }
        else if (acao == 'previsao_mes'){
          this.filtros.tipo_data = 'PREVISÃO'
          this.date_m =  this.mes_atual  ; //formato us yyyy-mm
          this.month_select()
        }
        else if (acao == 'previsao_mes_proximo'){
          this.filtros.tipo_data = 'PREVISÃO'
          // jogo pro mes seguinte
          this.date_m =  this.mes_seguinte  ; //formato us yyyy-mm
          this.month_select()
        }
        else if ((acao == 'previsao_mes_pendente')){
          this.filtros.tipo_data = 'PREVISÃO'
          this.date_m =  this.mes_atual  ; //formato us yyyy-mm
          this.month_select()
        }
        
        else if (acao == 'atrasado_hoje'){
          this.filtros.tipo_data = 'ATRASADO'
          this.in_today = this.today
          this.day_click()
        }
        else if (acao == 'atrasado_mes'){
          this.filtros.tipo_data = 'ATRASADO'
          this.month_select()
        }
        
        else if (acao == 'lancados_hoje'){
          this.filtros.tipo_data = 'LANÇADOS'
          this.in_today = this.today
          this.day_click()
        }
        else if (acao == 'lancados_mes'){
          this.filtros.tipo_data = 'LANÇADOS'
          this.month_select()
        }

        // this.open_contas(this.di, this.df)

      },

      monta_menu_parcelas(){

        this.menu_parcelas.push(           

            // {type:'menu', title: 'EFETUADO',        icon:'mdi-text-box',            icon_color: '',    click:'contrato' },
            // {type:'menu', title: 'PREVISÃO',      icon:'mdi-text-box',             icon_color: '',    click:'declaracao' },
            // {type:'menu', title: 'ATRASADO',           icon:'mdi-account-box',         icon_color: '',    click:'ficha' },
            // {type:'menu', title: 'LANÇADOS', icon:'mdi-book-check-outline ', icon_color: '',    click:'pre-solicitacao' },

            {
                type:'group',
                icon:'mdi-square',
                icon_color: 'grey',
                title: 'ÍTENS SELECIONADOS',
                click: '', 
                active: true, 
                items: [
                  {type:'', title: 'Gerar Cobranças',  icon:'mdi-barcode', icon_color: 'black',  click:'all_gerar_cobranca' },
                  {type:'', title: 'Enviar Cobranças (A)', icon:'mdi-whatsapp', icon_color: 'blue',  click:'all_cobranca_zap_a' },
                    {type:'', title: 'Enviar Email´s [$]',    icon:'mdi-email-arrow-right', icon_color: 'blue',  click:'all_cobranca_mail' },
                    {type:'', title: 'Efetuar',    icon:'mdi-check-circle', icon_color: 'green',  click:'all_efetuar' },
                    {type:'', title: 'Estornar',    icon:'mdi-check-decagram', icon_color: 'red',  click:'all_estornar' },
                    {type:'', title: 'Excluir Parcelas',     icon:'mdi-close-thick', icon_color: 'red',  click:'all_del_cobranca' },
                    {type:'', title: 'Inverter Tipo (Entrada/Saida)',  icon:'mdi-square', icon_color: 'red',  click:'inverter_tipo' },

                ],
            },

            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'blue',
                title: 'ESTE',
                click: '', 
                active: true, 
                items: [
                    {type:'', title: 'Alterar',             icon:'mdi-pencil', icon_color: 'blue',  click:'acao_editar' },
                    {type:'', title: 'Efetuar',             icon:'mdi-check-circle', icon_color: 'green',  click:'all_efetuar' },

                    {type:'', title: 'Recibo',              icon:'mdi-printer', icon_color: 'blue',  click:'imprimir_recibo' },
                    {type:'', title: 'Cadastro',            icon:'mdi-account', icon_color: 'blue',  click:'acao_abrir_cadastro' },
                    // {type:'', title: 'Efetuar', icon:'mdi-check-circle', icon_color: 'green',  click:'acao_editar' },
                    {type:'', title: 'Efetuar Parcial',     icon:'mdi-check-underline-circle', icon_color: 'orange',  click:'acao_efetuar_parcial' },
                    {type:'', title: 'Calcular Juros',      icon:'mdi-percent', icon_color: 'blue',  click:'acao_calc_juros' },
                    {type:'', title: 'Gerar Cobrança',      icon:'mdi-barcode', icon_color: 'black',  click:'acao_gerar_cobranca' },
                    {type:'', title: 'Ver Cobrança',        icon:'mdi-web', icon_color: 'primary',  click:'acao_ver_cobranca' },
                    {type:'', title: 'Enviar Cobrança (M)', icon:'mdi-whatsapp', icon_color: 'green',  click:'acao_cobranca_zap_m' },
                    {type:'', title: 'Enviar Cobrança (A)', icon:'mdi-whatsapp', icon_color: 'blue',  click:'acao_cobranca_zap_a' },
                    {type:'', title: 'Enviar Email [$]',    icon:'mdi-email-arrow-right', icon_color: 'blue',  click:'acao_cobranca_mail' },
                    {type:'', title: 'Excluir Parcela',     icon:'mdi-close-thick', icon_color: 'red',  click:'acao_del_cobranca' },
                    {type:'', title: 'Inverter Tipo (Entrada/Saida)',  icon:'mdi-square', icon_color: 'red',  click:'inverter_tipo' },

                ],
            },


            
        )
      },

      monta_menu_tipo_data(){

        if (!this.pode_alterar()){

          // acesso limitado no financeiro, somente coisas diarias
          this.menu_tipo_data.push(
            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'blue',
                title: 'EFETUADO',
                click: '', 
                items: [
                    {type:'', title: 'Hoje',        icon:'', icon_color: 'red',  click:'efetuado_hoje' },
                ],
            },

            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'orange',
                title: 'PREVISÃO',
                click: '', 
                items: [
                    {type:'', title: 'Hoje',                   icon:'', icon_color: '',  click:'previsao_hoje' },
                ],
            },

          )

        }else{
        
            this.menu_tipo_data.push(

            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'blue',
                title: 'EFETUADO',
                click: '', 
                items: [
                    {type:'', title: 'Hoje',        icon:'', icon_color: 'red',  click:'efetuado_hoje' },
                    {type:'', title: 'No mês',      icon:'', icon_color: '',  click:'efetuado_mes' },
                ],
            },


            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'red',
                title: 'ATRASADO',
                click: '', 
                items: [
                    {type:'', title: 'Até Hoje',    icon:'', icon_color: '',  click:'atrasado_hoje' },
                    {type:'', title: 'No mês',      icon:'', icon_color: '',  click:'atrasado_mes' },
                ],
            },

            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'orange',
                title: 'PREVISÃO',
                click: '', 
                items: [
                    {type:'', title: 'Hoje',                   icon:'', icon_color: '',  click:'previsao_hoje' },
                    {type:'', title: 'No mês',                 icon:'', icon_color: '',  click:'previsao_mes' },
                    // {type:'', title: 'No próximo mês',         icon:'', icon_color: '',  click:'previsao_mes_proximo' },
                    {type:'', title: 'No mês (pendente)',      icon:'', icon_color: '',  click:'previsao_mes_pendente' },
                    // {type:'', title: 'Contas a Receber',       icon:'mdi-square ', icon_color: 'green',  click:'previsao_receber_hoje' },
                    // {type:'', title: 'Contas a Pagar',         icon:'mdi-square', icon_color: 'red',  click:'previsao_pagar_hoje' },
                ],
            },

            {
                type:'group',
                icon:'mdi-circle',
                icon_color: 'green',
                title: 'LANÇADOS',
                click: '', 
                items: [
                    {type:'', title: 'Hoje',        icon:'', icon_color: '',  click:'lancados_hoje' },
                    {type:'', title: 'No mês',      icon:'', icon_color: '',  click:'lancados_mes' },
                ],
            },



            {type:'menu', title: 'A RECEBER',    icon:'mdi-square', icon_color: 'green',  click:'previsao_receber_hoje' },
            {type:'menu', title: 'A PAGAR',      icon:'mdi-square', icon_color: 'red',    click:'previsao_pagar_hoje' },

            
           )
        }      
      },


      async executar_acao(item, tipo, texto_alerta){

        console.log(`executar_acao: ${tipo}:`, item);

        this.overlay = true

        if (item.constructor === Array){//selecionados
          // console.log('é array');

          this.selected_copia = this.selected.map((x) => x);//copio para depois manter a selecao

          this.alerta(`${texto_alerta}`,'green')

          for (let i = 0; i < item?.length; i++) {

             if (tipo == 'pix'){
                await this.gerar_pix_link(item[i], 'N')
             }
             
             else if (tipo == 'whats_cobranca'){
                await this.enviar_whats_dsi(item[i], tipo)
             }

             else if (tipo == 'inverter_tipo'){
                await this.go_inverter_tipo(item[i])
             }
            
           }
        }
        else{//somente um
          // console.log('NAO é array');

          this.selected_copia  = [ Object.assign({}, item)] //copio para depois manter a selecao
          if (tipo == 'pix'){
            await this.gerar_pix_link(item, 'S')
          }
          else if (tipo == 'whats_cobranca'){
            await this.enviar_whats_dsi(item, tipo)
          }
          
        }



        await atualiza(this)
            async function atualiza(that){
              setTimeout(function(){      

                that.open_contas(that.di, that.df)
                that.alerta('Concluído','blue')
                that.overlay = false
                that.selected = that.selected_copia.map((x) => x);//manter selecionados

              }.bind(that), 555);
            }


            // atualizo o pix_url dos selecionados.. para enviar email ou outra coisa
            setTimeout(function(){
                this.atualizaSelecao()
            }.bind(this), 1500);

      },

      remove_lista(tipo, item){
        
        // FILTRO POR TIPO DE VARIAVEL
        if (item.constructor === String){
          // alert('string')
        }else if (item.constructor === Object){
          // alert('objeto')
          item = item[tipo]
        }

        
        if (confirm(`Deseja Remover ${item} desta lista ?`)){

          // EXECUTA SQL
           let sql = `update conta set VER_${tipo} = 'N' where ${tipo} = '${item}'`
          //  alert(sql)
          this.crud_sql(sql)


          // REMOVE DA LISTA
           let index = this['items_tabela_'+tipo.toLowerCase()].findIndex( x => x.CATEGORIA === item );
          //  alert(index)
           this['items_tabela_'+tipo.toLowerCase()].splice(index, 1);//removo a coluna senha
           
        }
      },


      apertou_enter(nome){

        this['items_tabela_'+nome.toLowerCase()].unshift(this.edita_parcelas[nome]?.toUpperCase())
        // this.items_tabela_categoria.unshift(this.edita_parcelas[nome]?.toUpperCase())
      },



      async monta_items_combobox(){
        

        let sql = ''

        // sql = `select distinct CATEGORIA from conta where (CATEGORIA <>' ') and(VER_CATEGORIA = 'S') order by CATEGORIA`//ANTES DE JUNTAR COM A TABELA
        sql = `
        SELECT DISTINCT CATEGORIA, 'C' AS TIPO
          FROM conta 
          WHERE CATEGORIA <> ' ' 
          AND VER_CATEGORIA = 'S'

          UNION

          SELECT DISTINCT CONCAT(tabela_categorias.CATEGORIA, ' - ', tabela_categorias.OPERACAO) AS CATEGORIA, 'L' AS TIPO
          FROM tabela_categorias

          ORDER BY CATEGORIA;
        `
        
        await this.crud_abrir_tabela(sql, 'items_tabela_categoria' )

        await this.insere_todos('items_tabela_categoria', 'CATEGORIA')

        
        sql = `select distinct OPERACAO from conta where (operacao <>' ')and(tipo = 'S')`
        await this.crud_abrir_tabela(sql, 'items_tabela_operacao' )
        
        
      },

      async open_contas(di,df,limpar_selecao ){

          // SEQUENCIA DA ABERTURA INICIAL DO ATUALIZAR NO ONCREATED: =>oncreated - ler_sessions_parametros - clicou_menu_datas -  day_click - open_contas

        // alert('inicio B:'+ di)
        if ((!di) || (!df)){ 
          console.log('di vazio:',di);
          return false
        }

        this.pode_mostrar = false

        let tipo_data  = ''
        let order_by   = ''
        let conta      = ''
        let tipo       = ''
        let pago       = ''
        let categoria  = ''
        let forma      = ''


        if (limpar_selecao == 'S'){
         this.selected = []; //tem qeu limpar para atualizar os dados selecionados
        }



        // conta = parseInt(this.filtros.conta)
        conta = parseInt(this.filtros.conta.CODIGO)



        tipo_data = this.filtros.tipo_data
        if (tipo_data == 'EFETUADO'){
          tipo_data  = ` data_efetuado between '${di}' and '${df}' `
          order_by   =  ` order by data_efetuado, codigo desc `
        }

        else if (tipo_data.includes('PREVISÃO')){


          if ((this.acao_atual == 'previsao_mes_pendente')||(this.acao_atual == 'previsao_pagar_hoje') ||(this.acao_atual =='previsao_receber_hoje')){
            pago = ` and (pago = 'N')`
          }


          if (tipo_data == 'PREVISÃO_RECEBER' ){
            tipo_data  = ` (pago = 'N') and (tipo = 'E') and data_vencimento <= '${df}' `
          }
          else if (tipo_data == 'PREVISÃO_PAGAR' ){
            tipo_data  = ` (pago = 'N') and (tipo = 'S') and data_vencimento <= '${df}' `
          }
          else{
            tipo_data  = `  data_vencimento between '${di}' and '${df}' ${pago}`
          }

          order_by   =  ` order by data_vencimento, data_efetuado, codigo desc `
        }
        else if (tipo_data == 'LANÇADOS'){
          tipo_data  = ` data_venda between '${di}' and '${df}' `
          order_by  =  ` order by data_venda, codigo desc `
        }
        else if (tipo_data == 'ATRASADO'){
          if (this.tipo_dia == 0){
            //por dia
            // alert('dia')
            tipo_data  = ` (pago = 'N') and data_vencimento < '${this.today}' `
          }
          else{
            //por periodo
            // alert('periodo')
            tipo_data  = ` (pago = 'N') and data_vencimento between '${di}' and '${this.yesterday}' `
          }

          order_by  =  ` order by data_vencimento, codigo desc `
          
        }
        else if (tipo_data == 'A RECEBER'){

          
          let dii = this.incDay(this.today,-60)
          this.di = dii
          // this.df = this.today

          tipo_data  = ` (pago = 'N') and data_vencimento between '${di}' and '${df}' `
          order_by   =  ` order by data_vencimento, codigo desc `

          this.tipo_dia = 2
          this.filtros.tipo = 'E'
        }
        else if (tipo_data == 'A PAGAR'){

          let dii = this.incDay(this.today,-60)
          this.di = dii
          // this.df = this.today

          tipo_data  = ` (pago = 'N') and data_vencimento between '${di}' and '${df}' `
          order_by   =  ` order by data_vencimento, codigo desc `

          this.tipo_dia = 2
          this.filtros.tipo = 'S'
        }




        if (parseInt(conta)>0){
          conta = ` and codigo_conta = ${conta}`
        }else{
          conta = ''
        }

        tipo = this.filtros.tipo
        if (tipo){
          tipo  = ` and  tipo = '${tipo}'`
        }


        //filtro de categoria
        if (this.categoria_forma){
          categoria = this.filtros.categoria
          if (categoria!='TODOS'){
            // categoria  = ` and  conta.categoria like '%${categoria}%'`
            // categoria  = ` and ((conta.categoria like '%${categoria}%')or(conta.SERVICO_REFERENTE like '%${categoria}%'))`
            categoria  = ` and ((conta.categoria like '%${categoria}%')or(conta.SERVICO_REFERENTE like '%${categoria}%') or(conta.SERVICO_REFERENTE like '%${categoria}%'))`
          }else{
            categoria = ''
          }
        }
        else{
          // filtro de forma de pagamento
          forma = this.filtros.forma
          forma = ` and conta.forma_pagamento like '%${forma}%'`
        }


        let sqlWhere = `where ${tipo_data}  ${tipo} ${conta} ${categoria} ${forma}`

        this.sqlWhere_2 = sqlWhere// copio o where para ser usados por outras tabelas

        

        // let whereClientes  = ''
        // let fieldsClientes = ''
        // if (this.souDSi){
        //   fieldsClientes = `,clientes.NOME as X_NOME_CLIENTE, clientes.CELULAR as X_CELULAR `
        //   whereClientes = `LEFT OUTER JOIN clientes ON (CONTA.CODIGO_CLIENTE=clientes.CODIGO)`
        // }


        // let sql = `select * from conta ${sqlWhere}`
        let sql = `
        SELECT
          conta.CODIGO,
          conta.CODIGO_ALUNO,
          conta.CODIGO_CLIENTE,
          conta.CODIGO_SERVICO,
          conta.CODIGO_CONTA,
          conta.DATA_VENDA,
          conta.DATA_VENCIMENTO,
          conta.DATA_EFETUADO,
          conta.PAGO,
          conta.PLANO,
          conta.double_VALOR,
          conta.double_JUROS,
          conta.double_TAXA,
          conta.double_TOTAL,
          conta.FORMA_PAGAMENTO,
          conta.VISTO,
          conta.TIPO,
          conta.CATEGORIA,
          conta.OPERACAO,
          conta.SERVICO_REFERENTE,
          conta.OBSERVACAO,
          conta.QUEM_ALTEROU,
          conta.QUEM_EFETUOU,
          conta.PIX_ID,
          conta.PIX_GERADO,
          conta.PIX_URL,
          conta.MULTA,
          conta.JUROS,
          conta.ENVIOU_WHATS,
          conta.ENVIOU_EMAIL,
          conta.PIX_PLATAFORMA,
          conta.WHATS_ID,
          conta.WHATS_STATUS,
          conta.CODIGO_ALUNO,
          conta.CODIGO_SERVICO,
          
          clientes.NOME as X_NOME_CLIENTE,
          clientes.CELULAR as X_CELULAR_CLIENTE,
          clientes.EMAIL as X_EMAIL,

          alunos.NOME as X_NOME_ALUNO,
          alunos.CELULAR as X_CELULAR_ALUNO,

          tabela_contas.DESCRICAO as CONTA_REFERENTE

        FROM
          conta
          LEFT OUTER JOIN clientes ON (CONTA.CODIGO_CLIENTE=clientes.CODIGO)
          LEFT OUTER JOIN alunos ON (CONTA.CODIGO_ALUNO=alunos.CODIGO_ALUNO)
          LEFT OUTER JOIN tabela_contas ON (CONTA.CODIGO_CONTA=tabela_contas.CODIGO)
        ${sqlWhere} ${order_by}`

        console.log(sql);

        await this.crud_abrir_tabela(sql, 'items_conta' )

        await this.open_sum_contas(sqlWhere)//reaproveito o sqlWhere

        if (limpar_selecao !='S'){
           await this.atualizaSelecao()
        }
        // console.log(this.items_conta);

        if (this.expansion_selecionao == 'FORMAS'){
          await this.estatisticas_formas()
        }

        if (this.expansion_selecionao == 'CATEGORIA'){
          await this.estatisticas_categoria()
        }


        this.pode_mostrar = true
      },

      seleciona_forma_pagamento(){

          let FORMA   =  this.edita_parcelas.FORMA_PAGAMENTO.DESCRICAO
          let DIA     =  this.edita_parcelas.FORMA_PAGAMENTO.DIA
          let VENCE   =  this.edita_parcelas.DATA_VENCIMENTO

          this.$set(this.edita_parcelas, 'FORMA_PAGAMENTO', FORMA)

          if (DIA>0){//acha o dia do vencimento
            //  let nova_data =  VENCE.slice(0, 8) + DIA
             let nova_data =  this.df.slice(0, 8) + DIA //pega do mes que selecionei
            this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', nova_data)
          }
          else{
            // this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', this.today)
          }
      },

      async muda_visto(item, perguntar){
        // console.log('muda_visto:', item);


        if (this.obj_user.SUPER!='S'){
          alert('Somente Super pode alterar o Visto')
          return 'exit'
        }

        if (perguntar){
          if (!confirm("Alterar Visto?")){
            return 'exit'
          }
        }



        let visto = item.VISTO

        // alert(visto)
        if (visto != 'S'){ visto = 'S'} else {visto = 'N'}
        let codigo = item.CODIGO

        let sql = `update conta set conta.VISTO = '${visto}' where CODIGO = ${codigo}`
        this.crud_sql(sql)
        // console.log('sql:', sql);
        

        // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
        // let index = this.items_conta.indexOf(item)
        let index = this.items_conta.findIndex( x => x.CODIGO === item.CODIGO );
        this.$set(item, 'VISTO',  visto)
        Object.assign(this.items_conta[index], item)

      },

      SearchNoVisibles (value, search, item) {
        let r =  Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
        // console.log('item:', item);
        return  r
      },

      async showDrawer(value){
        this.$emit('executar', value)//mostrar ou nao paineis
        this.can_show = true
        this.isMobile = window.innerWidth < 600
        this.mostrar_filtros = !this.isMobile
      },


      async enviar_whats(item, tabela, tipo){

        if (tabela == 'clientes'){
           if (tipo == 'pix'){
             console.log('enviar_whats:');
             console.log(item);

             let enter = '%0A'
             let msg = `Olá${enter}*${item.OBSERVACAO}*, sua parcela com vencimento em *${item.x_DATA_VENCIMENTO}* no valor de *${item.x_double_VALOR}* poderá ser efetuada via *PIX* ou *BOLETO*. 
             ${enter}${enter}*Clique no link para ver as Opções de Pagamento:*  ${enter} 👉🏼 ${item.PIX_URL}
             ${enter}${enter}*Observação:*
             ${enter}Para nosso controle, *efetue* o *pagamento somente através deste link*.
             ${enter}(nosso sistema reconhecerá *automáticamente* o pagamento)😉`

             
             msg = msg?.replaceAll('&','%26') //removo caracter especial
             

             this.send_whats(item.X_CELULAR_CLIENTE, item.OBSERVACAO, 0, msg)

             let codigo = item.CODIGO
             let sql = `update conta set conta.ENVIOU_WHATS = 'S', WHATS_STATUS = 'enviado manualmente' where CODIGO = ${codigo}`
             this.crud_sql(sql)

            // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
            // let index = this.items_conta.indexOf(item)
            let index = this.items_conta.findIndex( x => x.CODIGO === item.CODIGO );
            this.$set(item, 'ENVIOU_WHATS', 'S')
            Object.assign(this.items_conta[index], item)

           }
        }

      },

      async abri_dados_cliente(codigo){
          let sql = `select * from clientes where codigo = ${codigo}`
          console.log(sql);

          await this.crud_abrir_tabela(sql, 'items_conta' )
      }, 

      update_in_today(){

          this.di = this.in_today;
          this.df = this.in_today;   

          if (this.souDSi){
            // this.di = '2021-02-02'//teste
            // this.df = '2023-02-02'//teste
          }
            
          this.open_contas(this.di, this.df)
      },


      async prepara_email(item, qual){

         let para    = '' 
         let assunto = ''
         let texto   = ''
         let link    = ''
        //  let enter   = '\n'
         let enter   = '<br/>'

         let codigo  = 0
         let r       = ''

         let titulo = `<a href="https://sistemasdsi.com.br"> <img src="https://dsi.app.br/logos/nova_dsi2.png" height="130" alt="Sistemas DSi."> </a> <br/><br/>`
         
         let obs   = `${enter}${enter}<b>Observação:</b>
                        ${enter}Para nosso controle, <b>efetue o pagamento somente através deste link</b>.
                        ${enter}(nosso sistema reconhecerá <b>automáticamente</b> o pagamento) 😉 
                        ${enter}<h2>Sistemas DSi</h2>`



         if (qual == 'este'){
          //  console.log(item);

           
           codigo  = item.CODIGO
          //  para    = item.X_EMAIL + ';contato@sistemasdsi.com.br' //com copia para mim.. pode tirar isso depois
           para    = item.X_EMAIL
          //  para    = 'contato.dsi@gmail.com'
           assunto = item.X_NOME_CLIENTE +' - Lembrete de Pagamento'
          //  link    = `http://localhost/vue_cfc/public/clientes/?token=pi45i${item.CODIGO_CLIENTE}`//teste
           link    = item.PIX_URL

           link  = `<p> Clique no link para ver as <b>Opções de Pagamento: </b> </p>  <a href =${link}>${link}</a>`

           texto =  `${titulo} ${enter} Olá <b>${item.X_NOME_CLIENTE}</b>,${enter}${enter} Sua parcela com <b>Vencimento</b> em <b>${item.x_DATA_VENCIMENTO}</b> no valor de <b>${item.x_double_VALOR}</b> esta disponível.${enter} ${link} ${enter}${enter} ${obs}`


          r =  await this.enviar_email(para, assunto, texto)
          if (r == 'OK'){
            // this.alerta('Email enviado com sucesso!','blue')
            let sql = `update conta set conta.ENVIOU_EMAIL = 'S' where CODIGO = ${codigo}`
            this.crud_sql(sql)
            
            // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
            // let index = this.items_conta.indexOf(item)
            let index = this.items_conta.findIndex( x => x.CODIGO === item.CODIGO );
            this.$set(item, 'ENVIOU_EMAIL', 'S')
            Object.assign(this.items_conta[index], item)

          }

         }
         else if (qual == 'selecionados'){
          // console.log(item);

            let qt = item?.length
            for (let i = 0; i < qt; i++){
              
              
               codigo  = item[i].CODIGO
              //  para    = item[i].X_EMAIL
               para    = item[i].X_EMAIL+ ';contato@sistemasdsi.com.br' //com copia para mim.. pode tirar isso depois
              //  para    = 'contato.dsi@gmail.com'
               assunto = item[i].X_NOME_CLIENTE
               link    = item[i].PIX_URL

               link  = `<p> Clique no link para ver as <b>Opções de Pagamento: </b> </p>  <a href =${link}>${link}</a>`


               texto = ` ${titulo} ${enter} Olá <b>${item[i].X_NOME_CLIENTE}</b>,${enter}${enter} Sua parcela com <b>Vencimento</b> em <b>${item[i].x_DATA_VENCIMENTO}</b> no valor de <b>${item[i].x_double_VALOR}</b> esta disponível.${enter} ${link} ${enter}${enter} ${obs}`

                
                  

               r = await this.enviar_email(para, assunto, texto)
               
               if (r == 'OK'){

                  console.log('OK - '+item[i].X_NOME_CLIENTE);
                  let sql = `update conta set conta.ENVIOU_EMAIL = 'S' where CODIGO = ${codigo}`
                  // console.log(sql);
                  this.crud_sql(sql)


                  // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
                  let index = this.items_conta.findIndex( x => x.CODIGO === item[i].CODIGO );
                  console.log('index:'+ index);
                  this.$set(item[i], 'ENVIOU_EMAIL', 'S')
                  Object.assign(this.items_conta[index], item[i])

               }

            }

         }

      },

      async enviar_whats_dsi(item, tipo){
          

          if (!this.config_whats){
            alert('WhatsApp não configurado!')
            return false
          }

          var link = this.config_whats.API_LINK
          

          // return 'exit'

          let celular = item.X_CELULAR_CLIENTE
          
          // let celular = '5511 93929-3119'
          let texto   = item.X_NOME_CLIENTE +' - '+ item.SERVICO_REFERENTE
          let msg = ''
          let enter = '\n'
          let img

          if (tipo == 'whats_cobranca'){

             msg = `Olá${enter}*${item.OBSERVACAO}*, sou a secretária virtual @av de *Sistemas DSi*, sua parcela com vencimento em *${item.x_DATA_VENCIMENTO}* no valor de *${item.x_double_VALOR}* poderá ser efetuada via *PIX* ou *BOLETO*. 
             ${enter}@h_dir  *Link de Pagamento:*  ${enter}  ${item.PIX_URL}` 
            //  +`${enter}*Observação:*`
            //  +`${enter}Para nosso controle, *efetue* o *pagamento somente através deste link*.`
             +`${enter}`
            //  (nosso sistema reconhecerá *automáticamente* o pagamento)@;u\n
            //  `* (Se o *link* não estiver ativo, *envie OK como resposta!*)\n`
             +`\nCaso o pagamento já tenha sido efetuado, por favor, desconsidere esta mensagem.`
             +`${enter}${enter}*Sistemas DSi* - Lembrete Automático`
             

             msg = msg?.replaceAll('&','%26') //removo caracter especial

            //  isso daqui somente para clinicas ou auto escolas
            //  msg += `${enter}${enter}* *Mensagem enviada automáticamente* * \n*Caso necessite falar com um atendende, clique no link abaixo:* ${enter} https://wa.me/5544999685172&text=Olá`
            

             texto = msg
             img = `https://dsi.app.br/logos/nova_dsi2.png`
            
          }




          const retorno = await send_whats_api('COBRANÇA', celular, texto, img);
          // console.log('retorno:', retorno);

          // ZAPI
          // console.log(data);
          // const header =   { headers: {'Content-Type': 'application/json'}}
          // const res = await axios.post(link, data, header);

          await executa(this, item, retorno)
            async function executa(that, item, retorno){
              if (retorno.id){
                
                  let codigo = item.CODIGO
                  let sql = `update conta set conta.WHATS_ID = '${retorno.id}', conta.ENVIOU_WHATS = 'T', WHATS_STATUS = 'envio automático' where CODIGO = ${codigo}`
                  // let sql = `update conta set conta.ENVIOU_WHATS = 'T' where CODIGO = ${codigo}`
                  // console.log(sql);
                  await that.crud_sql(sql)

                  // console.log('item:', item);
                  // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
                  let index = that.items_conta.findIndex( x => x.CODIGO === item.CODIGO );
                  // console.log('index:', index);
                  that.$set(item, 'ENVIOU_WHATS', 'S')
                  Object.assign(that.items_conta[index], item)

              }
            }

          
          // return res.data          

      }, 

      async enviar_email(para, assunto, texto){
          // console.log('enviar_email:');

          var url =  sessionStorage['url']
          let link = url+"email.php"

          const data = {
                    
                    para:      para,
                    assunto:   assunto, 
                    texto:     texto,
          }  

          // alert(link)
          // console.log(data);
          const header =   { headers: {'Content-Type': 'multipart/form-data'}}

          const res = await axios.post(link, data, header);
          console.log(res.data);
          return res.data

      }, 

      async consultar_pix_link(item){
          console.log(item);

          let link = ''
          let teste = sessionStorage['url'];
          let plataforma = item.PIX_PLATAFORMA


          if (plataforma == 'mercadopago'){
              if (teste == '/api/'){
                link = `http://localhost/vue_cfc/public/clientes/wh/?acao=${plataforma}&cliente=45&codigo=${item.CODIGO_CLIENTE}&id=${item.PIX_ID}&topic=payment&update=N`//teste
              }
              else{
                link = `https://dsi.app.br/f/c/clientes/wh/?acao=${plataforma}&cliente=45&codigo=${item.CODIGO_CLIENTE}&id=${item.PIX_ID}&topic=payment&update=N`//producao  
              }
          }

          else if (plataforma == 'mercadopago'){

            // TEM QUE ENVIAR UM GET PRO ASAAS PARA PEGAR O OBJ E DAI ENVIAR PARA O INDEX.PHP DO WEBHOOK! VER SE BUSCO AQUI OU BUSCO DIRETO NO INDEX.PHP DO WEBHOOK
            // https://sandbox.api.asaas.com/v3/payments?id=pay_8073413225588620
              if (teste == '/api/'){
                // link = `http://localhost/vue_cfc/public/clientes/wh/?acao=${plataforma}&cliente=45&codigo=${item.CODIGO_CLIENTE}&id=${item.PIX_ID}&topic=payment&update=N`//teste
              }
              else{
                // link = `https://dsi.app.br/f/c/clientes/wh/?acao=${plataforma}&cliente=45&codigo=${item.CODIGO_CLIENTE}&id=${item.PIX_ID}&topic=payment&update=N`//producao  
              }
          }

          console.log(link);

          // leio o retonro e vejo se esta na validade o pix ainda.. caso contratio.. gera novo pix               

          const res = await axios.get(link);
          console.log(res.data);

      }, 


     async atualizaSelecao(){

      let percorrer = this.selected.map((x) => x);//clonar array selecao para pegar os codigos ja selecionados
      this.selected = []//limpo a selecao
      for (let i = 0; i < percorrer?.length; i++){//percorro o clone dos codigos selecionados

          // console.log('percorrer[i]:'+ i);
          // console.log(percorrer[i]);

          if (percorrer[i]){
            let achar = this.items_conta.filter(x => (x.CODIGO === percorrer[i].CODIGO));//clono somente os codigos da lista atualizada
            achar = achar[0] //trasnformo array em objeto
            // console.log('achar:');
            // console.log(achar);
            this.selected.push(achar) //preenchendo array com o objeto clonado
          }
      }

     },

     async gerar_pix_link(item, refresh){

        // console.log(item);

        let link = ''
        let teste = sessionStorage['url'];
        if (teste == '/api/'){
          link = `http://localhost/vue_cfc/public/clientes/?token=pi45i${item.CODIGO_CLIENTE}`//teste
        }
        else{
          link = `https://dsi.app.br/f/c/clientes/?token=pi45i${item.CODIGO_CLIENTE}`//produção
        }
              
        // console.log(link);
        const res = await axios.get(link);
        console.log(res.data);


        if (refresh=='S'){
          window.open(res.data.pagina_retorno, '_blank');

          await this.open_contas(this.di, this.df)
        }

        // await this.open_contas(this.di, this.df)

      },


      retorna_altura(menos){
          // let r = '100vh'  
          // let r = '720'
          let r = (window.innerHeight - menos)
          let height = window.innerHeight;

          return r
      },

      async salvar_calculando(){


        // copio alguns parametros do lançamento..para o proximo
        this.$set(this.last_parametros, 'codigo_conta',    this.edita_parcelas.CODIGO_CONTA)
        this.$set(this.last_parametros, 'forma_pagamento', this.edita_parcelas.FORMA_PAGAMENTO)
        this.$set(this.last_parametros, 'data_vencimento', this.edita_parcelas.DATA_VENCIMENTO)



        await this.atualiza_juros(this.edita_parcelas, false, false)//nao uso da tabela-financeiras... uso do proprio campo salvo

        //multiplo..vezes...
        let ate = this.multiplicar
        let vence_ate = this.edita_parcelas.DATA_VENCIMENTO
        let nos_dois  = (this.edita_parcelas.TIPO == '2')
        

        for (let i = 0; i < ate; i++){

          this.overlay = true

          if (ate>1){//so se for mais de uma vez..
            this.$set(this.edita_parcelas, 'PLANO', (i+1)+'/'+ate)
            this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', this.incMonth(vence_ate, i,'us'))
          }

          console.log('item_menu:', this.item_menu);

          if (nos_dois){
            
            //  lanca entra e saida
            this.$set(this.edita_parcelas, 'TIPO', 'E')
            await this.salvar_tabela('conta', this.edita_parcelas,'dialog_alterar_parcela', this.item_menu)

            this.$set(this.edita_parcelas, 'TIPO', 'S')
            await this.salvar_tabela('conta', this.edita_parcelas,'dialog_alterar_parcela', this.item_menu)

          }
          else{
             //normal
             await this.salvar_tabela('conta', this.edita_parcelas,'dialog_alterar_parcela', this.item_menu)
          }

          this.overlay = false
        }


      },

      muda_pago(vem){
        
        this.data_hora_agora()

        let r = ''
        if (vem){
          r = 'S'
          // this.edita_parcelas.DATA_EFETUADO = this.today
          this.$set(this.edita_parcelas, 'DATA_EFETUADO', this.today)
        }
        else{
          r = 'N'
          // this.edita_parcelas.DATA_EFETUADO = null
          this.$set(this.edita_parcelas, 'DATA_EFETUADO', null)
        }

        // this.edita_parcelas.PAGO = r
        this.$set(this.edita_parcelas, 'PAGO', r)
        this.$set(this.edita_parcelas, 'VISTO', r)
        this.$set(this.edita_parcelas, 'QUEM_EFETUOU', this.quem_alterou)

      },

      imprimir_modelo(tipo, dados_financeiro,somas, titulo1, titulo2){

        sessionStorage['recibo_tipo'] = tipo

        // Transformar o objeto em string e salvar em sessionStorage
        sessionStorage['recibo_dados_financeiro']       =  JSON.stringify(dados_financeiro)
        sessionStorage['recibo_dados_financeiro_somas']  =  JSON.stringify(somas)

        sessionStorage['recibo_dados_titulo1']      =  titulo1
        sessionStorage['recibo_dados_titulo2']      =  titulo2

        let route = this.$router.resolve({path: '/print_c'});
        window.open(route.href, '_blank');

      },

      send_whats(cel,nome, ide, msg_enviar){
         DSibasico.send_whats(cel, nome, ide, msg_enviar)
      },

     strtofloat(vem){
         return DSibasico.strtofloat(vem)
      },

      floatTostring(vem){
         return DSibasico.floatTostring(vem)
      },

      floatTostring2(vem){
         return DSibasico.floatTostring2(vem)
      },

      async calcular_juros(item, usar_tabela){
        
        let multa = sessionStorage['emp_multa']
        let juros = sessionStorage['emp_juros']

        let m     = multa
        let j     = juros
        let j2    = 0
        let valor     = this.strtofloat(item.double_VALOR)
        let taxa      = this.strtofloat(item.double_TAXA)
        let juros_ja  = this.strtofloat(item.double_JUROS)
        let total = valor
        let total_juros = 0
        let string_juros = ''

        let dias = await DSibasico.diferenca_datas(item.DATA_VENCIMENTO, this.today)
        

        if (usar_tabela){  
          // se definiu as taxas em Tabelas-Financeiras
          m = ((m * valor) / 100)
          m = m.toFixed(2);

          j = ((j * valor) / 100)
          j = j.toFixed(2);
          j2 = (j * dias)

          if (dias >0){
            total_juros = parseFloat(m) + parseFloat(j2)
          }
        }
        else{
          // senao ver se tem juros salvo no proprio campo da parcela
          if (juros_ja != ''){
          // if (juros_ja >0){
            total_juros = juros_ja
          }
        }

        // alert(total_juros)

        // 1º somas os juros
        total = parseFloat(valor) + parseFloat(total_juros) 


        // 2º depois subtrai as taxas
        if (taxa>0){
          total = parseFloat(total) - parseFloat(taxa)
        }

        
        total = this.floatTostring(total)
        total_juros = this.floatTostring2(total_juros)
        

        string_juros = `Após vencimento, cobrar multa de ${multa}% = R$ ${m} \n` +
                       `Após vencimento, cobrar juros de ${juros}% ao dia = R$ ${j}`


        let retorno = {
          dias_atrasado : dias,
          valor : valor,
          total : total,
          multa : m,
          juros : j2,
          total_juros: total_juros,
          string_juros: string_juros
        }

        return retorno

      },


      calcula(){
        // if (this.showJuros){
          // console.log('calcula');
          this.atualiza_juros(this.edita_parcelas, false, false)
        // }
      },

      async atualiza_juros(item, usar_tabela, ja_salva){
        let r = await this.calcular_juros(item, usar_tabela)

        // console.log('atualiza_juros');
        // console.log(r);
        // console.log(item);

        this.data_hora_agora()
        this.$set(this.edita_parcelas, 'QUEM_ALTEROU', this.quem_alterou)


          if (this.showJuros){
            this.$set(item, 'double_TOTAL', r.total)
            this.$set(item, 'double_JUROS', r.total_juros)
          }
          else{
            this.$set(item, 'double_TOTAL', this.edita_parcelas.double_VALOR)
          }


          // caso queria calcular vindo de um menu ou botao
          if (ja_salva){
            await this.salvar_tabela('conta', item ,'')
          }
          
      },



      monta_menu_datas(){
        this.menu_datas.push( { title: 'Dia', icon: 'mdi-calendar-blank', icon_color: "blue", click: 'dia' } )
        this.menu_datas.push( { title: 'Mês', icon: 'mdi-calendar-month ', icon_color: "blue", click: 'mes' } )
        this.menu_datas.push( { title: 'Período', icon: 'mdi-calendar-blank-multiple ', icon_color: "blue", click: 'periodo' } )
      

        this.menu_header.push( { title: 'Imprimir.', icon: 'mdi-printer ', icon_color: "blue", click: 'financeiro' } )

        if ( (this.obj_user.SUPER=='S') || (this.obj_user.DRE=='S') ){
           this.menu_header.push( { title: 'Excel', icon: 'mdi-file-excel', icon_color: "green", click: 'gerar_excel', new:'N' } )
           this.menu_header.push( { title: 'DRE', icon: 'mdi-chart-box', icon_color: "blue", click: 'abrir_dre', new:'S' } )
           this.menu_header.push( { title: 'Dicas', icon: 'mdi-information', icon_color: "blue", click: 'abrir_dicas', new:'N' } )
        }

        this.items_tabela_categoria = ['A','B']
      },


      clicou_menu_header(acao){
        if (acao == 'financeiro'){

           let titulo1 = 'FINANCEIRO - '+  this.filtros.tipo_data 
           let titulo2 = 'PERÍODO: '+ this.getFormatDate(this.di) + ' até '+ this.getFormatDate(this.df) 
          //  alert(titulo)
           this.imprimir_modelo(acao, this.items_conta, this.somas, titulo1, titulo2)
        }

        if (acao == 'gerar_excel'){
           exportVisibleToExcel( this.items_conta, this.header_conta, 'Financeiro')//somente campos visiveis
          //  this.exportToExcel( 'items_conta', 'Financeiro')//pega todos os campos
        }

        else if (acao == 'abrir_dicas'){
          let lista = 
          `1-Dentro do campo Procurar: Digite (Shift + V) para vistar\n`+
          `2-Dentro da Lista: Digite (V) para vistar ou desvistar\n`+
          `3-Dentro da Lista: Digite (S) para selecionar a linha\n`+
          ``

          alert(lista)
        }
        else if (acao == 'abrir_dre'){

          sessionStorage['dre_show'] = 'S'

          let route = this.$router.resolve({path: '/dre'});
          window.open(route.href, '_blank');

          sessionStorage['dre_show'] = 'N'
        }
      },

      clicou_menu_datas(acao){

        if (acao==0){
          acao = 'dia'
        }

        localStorage['F:tipo_dia_string'] = acao

        // alert('clicou_menu_datas:'+ acao)

        if (acao == 'dia'){
          this.day_click()
        }
        else if (acao == 'mes'){
          this.month_select()
        }
        else if (acao == 'periodo'){
          this.periodo_click()
        }
      },

      cor_tipo_data(campo, retorna){
        
        let tipo = this.filtros.tipo_data

        let r = ''
        let l = ''
        if (tipo=='EFETUADO'){

            l = 'Efetuados em'
            if ((campo=='E')||(campo=='X')){
              r = 'blue--text'
            }
            
        }
        else if (tipo=='PREVISÃO'){

          l = 'Previsão para '
          if ((campo=='V')||(campo=='X')){
              r = 'orange--text'
            }
        }
        else if (tipo=='LANÇADOS'){

          l = 'Lançados em '
          if ((campo=='?')||(campo=='X')){
              r = 'green--text'
            }
        }
        else if (tipo=='ATRASADO'){

          l = 'Atrasadas até hoje'
          if ((campo=='V')||(campo=='X')){
              r = 'red--text'
            }
        }

        if (campo!='X'){
           r += ' font-weight-bold'
        }

        if (retorna == 'cor'){
          return r
        }
        else if (retorna == 'label'){
          return l
        }
        
      },

      async ler_sessions(){
    
        this.display_cobranca = `(${sessionStorage['emp_pix_plataforma']}) [${sessionStorage['emp_pix_situacao']}]`
            
        //busco objeto no sessionStorage
        this.config_emp   = JSON.parse(sessionStorage['config_emp'])
        
        try{
         this.config_pix   = (sessionStorage['config_pix']) ? JSON.parse(sessionStorage['config_pix']) : '';
        }catch(e){}

        try { this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa']) }
          catch (error) { console.log('erro:', error); }

        



        // deu erro pq quando clica ele nao limpa o array..fica duplicando
        // try {
        //   alert('here')
        //   const loadedModel = JSON.parse(sessionStorage['atrasadas_model']) || []
        //   this.atrasadas_model = Array.from(new Set([...this.atrasadas_model, ...loadedModel]))
        // } catch(e) {
        //   this.atrasadas_model = []
        // }
      

        // for(let i=0; i < a.length; i++) {
        //     this.atrasadas_model.push(a[i]);
        // }


        // try{
        //    this.atrasadas_model = JSON.parse(sessionStorage['atrasadas_model'])
        // }catch(e){
        //   this.atrasadas_model = []
        // }

        
        
    

        // try{
        //   this.previsao_model = []
        //   this.previsao_model = JSON.parse(sessionStorage['previsao_model'])
        //   // this.previsao_model = this.previsao_model.map((x) => x); 

        // }catch(e){}
        

        setTimeout(function(){
          try{
            this.config_whats = JSON.parse(sessionStorage['config_whats'])
          }catch(e){

            //tenta mais uma vez
            setTimeout(function(){
              try{
                this.config_whats = JSON.parse(sessionStorage['config_whats'])
              }catch(e){}
            }.bind(this), 999);

          }
        }.bind(this), 999);

        


        // this.mostrar_rodape = (localStorage['F:mostrar_rodape'] == 'true') 
        if (localStorage['F:mostrar_rodape']){
          // alert('sim tem salvo no session')
          this.mostrar_rodape = (localStorage['F:mostrar_rodape'] == 'true') 
        }       
        
        

        //busco objeto no sessionStorage
        this.obj_user = JSON.parse(sessionStorage['obj_user'])


        try{
          this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar']);
          this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado']);
        }
        catch (e) {
          console.log('NAO TEM ZAPZAP:', e);
        }


        // if (!this.tipo_dia == 2){//se escolheu o mes..
        //   setTimeout(function(){
        //     this.month_select()//refresh
        //   }.bind(this), 222);
        // }


      },


      estornar_items_selecionados(){

        if (confirm("Deseja realmente Estornar estes Lançamentos?")){
          

            this.data_hora_agora()

            let qt = this.selected.length
            let linha = {}
            for (let i = 0; i < qt; i++){
              
                // console.log('deletar: '+ this.selected[i].CODIGO);

                linha = this.selected[i]
                
                console.log('quem_alterou:', this.quem_alterou);

                let sql = `update conta set PAGO = 'N', VISTO = 'N', DATA_EFETUADO = null, QUEM_EFETUOU = '${this.quem_alterou}'  where CODIGO = ${linha.CODIGO}`
                // console.log(sql);
                this.crud_sql(sql)
            }


            setTimeout(function(){
              this.tabela('conta','update')//refresh
            }.bind(this), 555);
        }

      },

      efetuar_items_selecionados(){

        let qt = this.selected.length

        
        if (confirm( (qt>=2)? `Deseja realmente Efetuar estes Lançamentos?` :  `Deseja realmente Efetuar este Lançamento?`)){
          

            this.data_hora_agora()

            
            let linha = {}
            for (let i = 0; i < qt; i++){
              
                // console.log('deletar: '+ this.selected[i].CODIGO);

                linha = this.selected[i]
                

                let sql = `update conta set PAGO = 'S', VISTO = 'S', DATA_EFETUADO = '${this.today}', QUEM_EFETUOU = '${this.quem_alterou}'  where CODIGO = ${linha.CODIGO}`
                // console.log(sql);
                this.crud_sql(sql)
            }


            setTimeout(function(){
              this.tabela('conta','update')//refresh
            }.bind(this), 555);
        }

      },

      excluir_items_selecionados(){

        if (confirm("Deseja realmente Excluir os Lançamentos selecionados ?")){
          

            let qt = this.selected.length
            for (let i = 0; i < qt; i++){
              
                // console.log('deletar: '+ this.selected[i].CODIGO);

                let sql = 'delete from conta  where CODIGO = '+ this.selected[i].CODIGO
                // console.log(sql);
                this.crud_sql(sql)
            }


            setTimeout(function(){
              this.tabela('conta','update')//refresh
            }.bind(this), 555);
        }

      },


      adicionar(tipo){

        this.dialog_alterar_parcela = true
        this.data_hora_agora()

        this.edita_parcelas = {}//limpa

        //definindo valores padrao default
        this.$set(this.edita_parcelas, 'DATA_VENDA', this.today)
        this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', this.today)
        
        this.$set(this.edita_parcelas, 'DATA_EFETUADO', null)
        this.$set(this.edita_parcelas, 'PAGO',      'N' )
        
        // this.$set(this.edita_parcelas, 'DATA_EFETUADO', this.today)
        // this.$set(this.edita_parcelas, 'PAGO',      'S' )

        if (this.filtros.conta.CODIGO > 0){
          this.$set(this.edita_parcelas, 'CONTA_REFERENTE', this.filtros.conta.DESCRICAO )
          this.$set(this.edita_parcelas, 'CODIGO_CONTA', this.filtros.conta.CODIGO )
        }
        else if (this.last_parametros.codigo_conta > 0){//pega parametros do ultimo lançamento
          this.$set(this.edita_parcelas, 'CONTA_REFERENTE', 'CFC' )//qualquer coisa
          this.$set(this.edita_parcelas, 'CODIGO_CONTA', this.last_parametros.codigo_conta )
          this.$set(this.edita_parcelas, 'FORMA_PAGAMENTO', this.last_parametros.forma_pagamento)
          this.$set(this.edita_parcelas, 'DATA_VENCIMENTO', this.last_parametros.data_vencimento)
        }
        else{
          this.$set(this.edita_parcelas, 'CONTA_REFERENTE', 'CFC' )//isso nem uso pois busco direto da tabela tabela_contas.DESCRICAO 
          this.$set(this.edita_parcelas, 'CODIGO_CONTA', '1' )
        }


        this.$set(this.edita_parcelas, 'double_JUROS', '0' )
        this.$set(this.edita_parcelas, 'double_TAXA', '0' )

        


        //DEFAULT
        this.$set(this.edita_parcelas, 'VER_CATEGORIA',          'S' )
        this.$set(this.edita_parcelas, 'VER_FORMA',              'S' )
        this.$set(this.edita_parcelas, 'VISTO',                  'N' )
        this.$set(this.edita_parcelas, 'TIPO',                   tipo )
        // this.$set(this.edita_parcelas, 'CATEGORIA'  ,         'RECEBIMENTO' )
        this.$set(this.edita_parcelas, 'QUEM_ALTEROU'  ,         this.quem_alterou )
      },

      oi($event, vem){
        console.log(vem);
        console.log(event);
        console.log(event.target.innerText);
      },

      change_conta(){
        let CODIGO =  this.edita_parcelas.CODIGO_CONTA.CODIGO
        let DESCRICAO =  this.edita_parcelas.CODIGO_CONTA.DESCRICAO
        
        this.$set(this.edita_parcelas, 'CODIGO_CONTA',     CODIGO )
        this.$set(this.edita_parcelas, 'CONTA_REFERENTE', DESCRICAO)
      },

      abre_menu(item){
        // alert('here')
        this.comp_focus = ''
        this.dialog_menu = true
        this.item_menu = item

        this.seleciona_linha(item)
      },

      rightClick(event, item) {
        // console.log(event)
        // console.log(item)
        this.abre_menu(item.item)
        event.preventDefault()
      },

      seleciona_linha(row) {
        // console.log(row);
        //  console.log(this.selected.length);


        this.comp_focus='data_table'

        if (this.selected?.length <= 1){
            // this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
            this.selected  = [ Object.assign({}, row)] 
        }

        this.item_clicou = row

        
      },

      async onCreated(){
  
        if (await logado(this)){

          await this.showDrawer(true)

          let hoje    = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
          this.date_m = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5)  ; //formato us yyyy-mm

          // await this.ler_sessions()
          await DSisql.crud_empresa()
          await this.ler_sessions()

          this.souDSi    = (sessionStorage['souDSi'] == 'S') ? true : false 
          this.showJuros = (sessionStorage['conf_mostrar_juros'] != 'SIM') ? false : true

          //busco objeto no sessionStorage
          this.obj_user = JSON.parse(sessionStorage['obj_user'])

          this.monta_menu_datas()
          this.monta_menu_tipo_data()
          this.monta_menu_parcelas()

          await this.monta_items_combobox()

          await this.permissoes_item()

          await this.refresh()
          // await this.ler_sessions()
          await this.ler_sessions_parametros()

          await this.comAtraso()

          await this.minhas_tarefas()

          this.registerKeyHandler(true)

          
          for (let i = 1; i <=48; i++) {
            this.lista_parcelas.push(i)
          }

          this.estatisticas_counts()
          

        }
        
      },


      async refresh(){
        await this.data_hora_agora()
        await this.set_defaults()
        await this.open_tabela_conta()
        await this.open_tabela_formas()
      },

      async permissoes_item(){
          
         //depois verificar se usuario pode ou nao ver isso


          if (this.souDSi){

              this.header_conta = [
              
              // { text: '..', value: 'actions', sortable: false, width:'90px'},
              { text: '?', value: '?', sortable: false, width:'99px'},
              { text: 'Visto', value: 'VISTO', width:'20px' },

              // { text: 'ENVIOU_EMAIL', value: 'ENVIOU_EMAIL' },
              // { text: 'ENVIOU_WHATS', value: 'ENVIOU_WHATS' },
              
              { text: 'Lançamento', value: 'x_DATA_VENDA',width:'10px' },
              { text: 'Vencimento', value: 'x_DATA_VENCIMENTO',width:'10px' },
              { text: 'Efetuado', value: 'x_DATA_EFETUADO',width:'10px' },

              { text: '(=) Valor', value: 'double_VALOR',width:'10px', width: this.w_valor },
              { text: '(=) Total', value: 'double_TOTAL',width:'10px', width: this.w_valor },
              

              // { text: 'Categoria', value: 'CATEGORIA',width:'90px' },
              // { text: 'Operação', value: 'OPERACAO',width:'160px' },
              { text: 'Referente', value: 'SERVICO_REFERENTE',width:'590px' },
              
              { text: 'Forma', value: 'FORMA_PAGAMENTO',width:'130px' },
              { text: 'Quem Efetuou', value: 'QUEM_EFETUOU' },
              { text: 'Conta', value: 'CONTA_REFERENTE',width:'10px' },

              { text: 'PLATAFORMA', value: 'PIX_PLATAFORMA' },
              { text: 'PIX ID', value: 'PIX_ID' },
              { text: 'WHATS_ID', value: 'WHATS_ID' },
              // { text: 'PIX_GERADO', value: 'PIX_GERADO' },

          
              { text: 'Alterou', value: 'QUEM_ALTEROU',width:'230px' },

              // { text: 'Email Cl', value: 'X_EMAIL' },

              { text: '(=) Valor', value: 'double_VALOR',width:'10px', width: this.w_valor },
              { text: '(+) Juros', value: 'double_JUROS',width:'10px',  },
              { text: '(-) Taxa', value: 'double_TAXA',width:'10px',  },
              { text: '(=) Total', value: 'double_TOTAL',width:'10px', width: this.w_valor },
              { text: 'CODIGO', value: 'CODIGO', width:'10px' },
              { text: 'Tipo', value: 'TIPO', width:'10px' },
            ]

            // ITEMS QUE MOSTRAR NO FILTRO DAS DATAS
            this.items_data = ['EFETUADO','PREVISÃO','ATRASADO','LANÇADOS']

          }
          else{
              //DEMAIS CLIENTES 
              this.header_conta = [
                
                // { text: '..', value: 'actions', sortable: false, width:'90px'},
                // { text: '?', value: '?', sortable: false, width:'85px'},
                { text: 'Codigo', value: 'CODIGO',width:'80px' },
                { text: 'Visto', value: 'VISTO', width:'20px' },
                { text: 'Conta', value: 'CONTA_REFERENTE',width:'70px' },
                // { text: 'Ver', value: 'VER_CATEGORIA',width:'10px' },
                

                { text: 'Lançamento', value: 'x_DATA_VENDA',width:'10px' },
                { text: 'Vencimento', value: 'x_DATA_VENCIMENTO',width:'10px' },
                { text: 'Efetuado', value: 'x_DATA_EFETUADO',width:'10px' },
                // { text: 'Pago', value: 'PAGO',width:'10px' },
                // { text: 'Plano', value: 'PLANO',width:'10px' },
                { text: '(=) Valor', value: 'double_VALOR',width:'10px', width: this.w_valor },
                { text: '(=) Total', value: 'double_TOTAL',width:'10px', width: this.w_valor },
                { text: 'Forma', value: 'FORMA_PAGAMENTO',width:'130px' },
                
                // { text: 'Categoria', value: 'CATEGORIA',width:'90px' },
                // { text: 'Operação', value: 'OPERACAO',width:'160px' },
                { text: 'Referente', value: 'SERVICO_REFERENTE',width:'600px' },

                
                // { text: 'Quem Efetuou', value: 'QUEM_EFETUOU' },

                // { text: 'PLATAFORMA', value: 'PIX_PLATAFORMA' },
                // { text: 'PIX ID', value: 'PIX_ID' },
                // { text: 'PIX_GERADO', value: 'PIX_GERADO' },
              

                { text: 'Quem Efetuou', value: 'QUEM_EFETUOU' },
                // { text: 'Alterou', value: 'QUEM_ALTEROU',width:'230px' },
                // { text: 'IDE', value: 'CODIGO_ALUNO',width:'10px' },


                { text: '(+) Juros', value: 'double_JUROS',width:'10px',  },
                { text: '(-) Taxa', value: 'double_TAXA',width:'10px',  },
                // { text: 'Tipo', value: 'TIPO', width:'10px' },
              ]


              // ITEMS QUE APARECE NO FILTRO DAS DATAS
              if (this.pode_alterar()){
                 this.items_data = ['EFETUADO','PREVISÃO','ATRASADO','LANÇADOS']
              }else {
                 this.items_data = ['EFETUADO','PREVISÃO']
              }

          }


          if (this.filtros.tipo_data != 'LANÇADOS'){
              // this.header_conta.splice(this.header_conta.findIndex(x => x.value === "DATA_VENDA") , 1);//removo a coluna senha
          }
  

          if (!this.showJuros){
              this.header_conta.splice(this.header_conta.findIndex(x => x.value === "double_VALOR") , 1);
              this.header_conta.splice(this.header_conta.findIndex(x => x.value === "double_JUROS") , 1);
              this.header_conta.splice(this.header_conta.findIndex(x => x.value === "double_TAXA") , 1);
          }

          
      },

      getCorTipo(e){

        let r = ''
        if (e=='E'){
          r = ' green--text'
        }
        else if (e=='S'){
          r = ' red--text'
        }

        return r
        // :class="[isMobile ? 'ml-0' : 'ml-1', 'mb-4', 'yellow--text']"
      },


      async open_sum_contas(sqlWhere){

        let sql = 
        `
        select 
          count(case
              when
                TIPO  = 'E'
              then
                CODIGO
          end)as c_entrada,
              
          sum(case
              when
                TIPO  = 'E'
              then
                double_TOTAL
          end)as s_entrada,
          
          count(case
              when
                TIPO  = 'S'
              then
                CODIGO
          end)as c_saida,
          
          sum(case
              when
                TIPO  = 'S'
              then
                double_TOTAL
          end)as s_saida,
              
          count(CODIGO)as c_total,
          0 as s_total
                  
          FROM
          conta ${sqlWhere}
        `

        // console.log(sql);
        await this.crud_abrir_tabela(sql, 'items_sum_conta' )

        
        await somas(this)

        async function somas(that){

          let entrada = parseFloat(that.items_sum_conta[0].s_entrada)
          if (!entrada) { entrada = 0 }

          let saida   = parseFloat(that.items_sum_conta[0].s_saida)
          if (!saida) { saida = 0 }
          
          let saldo =  entrada - saida 
          

          that.$set(that.somas, 'c_entrada', that.items_sum_conta[0].c_entrada)
          that.$set(that.somas, 's_entrada', that.items_sum_conta[0].s_entrada)

          that.$set(that.somas, 'c_saida', that.items_sum_conta[0].c_saida)
          that.$set(that.somas, 's_saida', that.items_sum_conta[0].s_saida)

          that.$set(that.somas, 'c_total', that.items_sum_conta[0].c_total)
          that.$set(that.somas, 's_total', saldo)

          // console.log('items_sum_conta:');
          // console.log(that.items_sum_conta);
        }

      },




      async open_tabela_formas(){
            let sql = 'select * from tabela_formas'
            this.crud_abrir_tabela(sql, 'items_tabela_formas' )
      },

      async open_tabela_conta(){
          let sql = `select * from tabela_contas where ativo = 'S'`
          await this.crud_abrir_tabela(sql, 'items_tabela_contas' )

          this.items_tabela_contas_all = this.items_tabela_contas.map((x) => x); 
          await this.insere_todos('items_tabela_contas_all', 'DESCRICAO')
          sessionStorage['array_conta_referente']  =  JSON.stringify(this.items_tabela_contas_all)//salvo na session, vai usar em DRE.vue


          this.items_tabela_contas_pix = this.items_tabela_contas.filter(x => x.CHAVE_PIX)

          // console.log('items_tabela_contas_all:');
          // console.log(this.items_tabela_contas_all);



          // carrega filtro tipo de conta entrada ou saidas
          // let obj_tipo = {}
          let obj_tipo = [
            {
              'CODIGO' : '',
              'DESCRICAO' : 'TODOS'
            },
            {
              'CODIGO' : 'E',
              'DESCRICAO' : 'ENTRADA'
            },
            {
              CODIGO :'S',
              DESCRICAO : 'SAIDA'
            },
          ]

          this.items_tipo = obj_tipo


      },

      async data_hora_agora(){
           DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
      },

      async set_defaults(){
  
          
          if (sessionStorage['f:di']){//caso tenha mudado a data entao pega da memoria
            
            let ddi = sessionStorage['f:di']
            let ddf = sessionStorage['f:df']

            this.in_today = this.today; //associa a data de hoje com o input do filter
            this.di       = ddi
            this.df       = ddf

          }
          else{
            this.in_today = this.today; //associa a data de hoje com o input do filter
            this.di       = this.today; //pega a data de hoje
            this.df       = this.today;
          }         


      },

      month_select(){
    
        localStorage['F:tipo_dia_string'] = 'mes'
        this.tipo_dia = 1

        var ym = this.date_m;

        var y = ym.substring(0,4);
        var m = ym.substring(5,7);

        var data = new Date(y,m,0);
        var days = data.getDate();

        this.di = y+'-'+m+'-01';
        this.df = y+'-'+m+'-'+days;

        this.menu = false;//fecha picker

        this.open_contas(this.di, this.df)

      },

      day_click(){

        localStorage['F:tipo_dia_string'] = 'dia'
        this.tipo_dia = 0

        this.di = this.in_today;
        this.df = this.in_today;
        // alert(this.in_today)
        // alert('before 5')       
        this.open_contas(this.di, this.df)//aqui abre primeiro
      },

      periodo_click(){
        this.tipo_dia = 2
        this.open_contas(this.di, this.df)
      },

      getFormatDate(data){
          return DSibasico.getFormatDate(data)
      },

      getFormatCurrency(v,string){
        return DSibasico.getFormatCurrency(v, string)
      },

      alerta(text,color, time){
          DSibasico.alerta(this, text, color, time)
      },

      moeda(event){
          return DSibasico.moeda(event)
      },

      allUpper(event, obj_name, field){
          this[obj_name][field] = DSibasico.allUpper(event, obj_name, field)
      },


        //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
      async salvar_tabela(tabela, obj_edita, dialog_name, obj_before){

         //faço uma copia dos ultimos dados alterados..pode ser usado em parcial...etc..
         this.item_menu_copia = Object.assign({}, obj_edita);//faço uma copia do original

         let before  = Object.assign({}, obj_before);//faço uma copia do original
        //  console.log('before:', before);

         await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name, before)

         if (this.dados_parcial){
            await this.calcula_parcial()
         }

      },

      async tabela_update(tabela, obj_edita, obj_before){
          await DSisql.tabela_update(this, tabela, obj_edita, obj_before)
      },

      async tabela_create(tabela, obj_edita){
          await DSisql.tabela_create(this, tabela, obj_edita)
      },

      //EXECUTAR UM SQL TIPO UPDATE
      async crud_sql (sql) {
          await DSisql.crud_sql(this, sql)
      },

      async crud_abrir_tabela(sql, items_name){
          return await DSisql.crud_abrir_tabela(this, sql, items_name)
      }, 

      editar_tabela(item, obj_edita_name, dialog_name) {
          DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
      },


      remover_tabela(tabela, obj_edita, titulo){
          DSisql.remover_tabela(this, tabela, obj_edita, titulo)
      },

      async tabela(tabela,acao){
          // this.tabela_selecionada = tabela
          // this.card_titulo = item.text

          this.selecionou_varios = false

          if (tabela=='conta'){
              if ( (acao == 'create') || (acao == 'delete') || (acao == 'update') ){
                 await this.open_contas(this.di, this.df) 
              }
          }
      },

      incDay(data,dias,pt){
          return DSibasico.incDay(data,dias,pt)
      },

      incMonth(data,meses,pt){
          return DSibasico.incMonth(data,meses,pt)
      },

    },//methods final

}
</script>

<style scoped>
  .container{
      max-width: 100%;
  }



  .theme--light.v-data-table tbody tr.v-data-table__selected {
    /* seleciona_linha cor */
    background: #eee9e9 !important;
    /* background: red !important; */
   }

    /* padding das colunas data-table  */
   /* td.text-start{ */
    .v-data-table >>> td.text-start{
      padding-left: 7px !important;
      padding-right: 7px !important;
      /* padding: 0px 2px !important; */
      /* background-color: red; */
   }

   /* padding dos titulos das colunas data-table */
   /* th.text-start{  */
   .v-data-table >>> th.text-start{
      padding: 0px 4px !important;
      /* background-color: red; */
   }


   @media print {
    .no-print {
      display: none !important;
    }

    .v-app,
    .v-footer,
    .v-navigation-drawer,
    .v-toolbar {
      display: none !important;
    }
  }

  .fs-6{
    font-size: 6px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }

  .count_item {
   position: relative !important;
   top: -2px;
   font-size: 80%;
   vertical-align: super;
   color: red;
  }


 .v-text-field_menor input {
    max-width: 80% !important;
  }


  /* .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 2px; 
   } */

   .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    align-self: center;
    margin: 0;
    /* min-width: 25px; */
    justify-content: flex-end;
  }


  /* .v-expansion-panel-content__wrap {
    padding: 0 10px 16px !important;
    flex: 1 1 auto !important;
    max-width: 100% !important;
   } */

   .v-expansion-panel-content__wrap{
     padding: 0 4px 2px !important;
   }

</style>